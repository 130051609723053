export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** Date custom scalar type */
  Date: any
}

export type AbancaFields = {
  periodicidad?: Maybe<Scalars['String']>
  tiempoDedicado?: Maybe<Scalars['Boolean']>
  volumenEstimado?: Maybe<Scalars['Int']>
  tiempoTarea?: Maybe<Scalars['Float']>
  tiempoTareaVolumen?: Maybe<Scalars['Float']>
  riesgoOperacion?: Maybe<Scalars['Boolean']>
  ahorroCostes?: Maybe<Scalars['Boolean']>
  fechaMax?: Maybe<Scalars['Date']>
  peticionCER?: Maybe<Scalars['Boolean']>
  collaborators?: Maybe<Array<Maybe<Collaborator>>>
  departmentLevels?: Maybe<Array<Maybe<Scalars['String']>>>
  departmentTitle?: Maybe<Scalars['String']>
  departmentValue?: Maybe<Scalars['String']>
}

export type AbancaFieldsInput = {
  periodicidad?: Maybe<Scalars['String']>
  tiempoDedicado?: Maybe<Scalars['Boolean']>
  volumenEstimado?: Maybe<Scalars['Int']>
  tiempoTarea?: Maybe<Scalars['Float']>
  tiempoTareaVolumen?: Maybe<Scalars['Float']>
  riesgoOperacion?: Maybe<Scalars['Boolean']>
  ahorroCostes?: Maybe<Scalars['Boolean']>
  fechaMax?: Maybe<Scalars['Date']>
  peticionCER?: Maybe<Scalars['Boolean']>
  collaborators?: Maybe<Array<Maybe<CollaboratorInput>>>
}

export type AdditionalQuestion = {
  id: Scalars['ID']
  text?: Maybe<Scalars['String']>
  type?: Maybe<AdditionalQuestionType>
  selectList?: Maybe<Array<Maybe<Scalars['String']>>>
  answer?: Maybe<Scalars['String']>
}

export type AdditionalQuestionAnswers = {
  answers?: Maybe<Array<Maybe<AnswerInput>>>
}

export type AdditionalQuestionInput = {
  id?: Maybe<Scalars['ID']>
  text?: Maybe<Scalars['String']>
  type?: Maybe<AdditionalQuestionType>
  selectList?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum AdditionalQuestionType {
  Number = 'NUMBER',
  Text = 'TEXT',
  Select = 'SELECT',
}

export type AnswerInput = {
  answer?: Maybe<Scalars['String']>
  id: Scalars['ID']
}

export type AppSettings = {
  points?: Maybe<Points>
  featuredIdeas?: Maybe<Array<Maybe<Scalars['String']>>>
  ideaTypes?: Maybe<Array<Maybe<IdeaType>>>
}

export type Attachment = {
  name: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
}

export type AttachmentInput = {
  name: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
}

export type Challenge = ChallengeData & {
  id: Scalars['ID']
  creator?: Maybe<User>
  creationDate?: Maybe<Scalars['Date']>
  name: Scalars['String']
  shortDescription: Scalars['String']
  description: Scalars['String']
  cover?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  attachments?: Maybe<Array<Maybe<Attachment>>>
  period?: Maybe<ChallengePeriod>
  ratingType: RatingType
  linkedIdeas?: Maybe<Array<Maybe<Idea>>>
  status: ChallengeStatus
  questions?: Maybe<Array<Maybe<ChallengeRatingQuestion>>>
  additionalQuestions?: Maybe<Array<Maybe<AdditionalQuestion>>>
  ideaQuestions: Array<Maybe<IdeaQuestion>>
  challengeEvolution?: Maybe<Array<Maybe<ChallengeEvolution>>>
  collaboration?: Maybe<CollaborationType>
  judges?: Maybe<Array<Maybe<Judge>>>
  enablePending?: Maybe<Scalars['Boolean']>
  pendingIdeas?: Maybe<Array<Maybe<Idea>>>
}

export type ChallengeData = {
  name: Scalars['String']
  id: Scalars['ID']
  period?: Maybe<ChallengePeriod>
  ratingType: RatingType
  status: ChallengeStatus
  ideaQuestions: Array<Maybe<IdeaQuestion>>
  enablePending?: Maybe<Scalars['Boolean']>
  collaboration?: Maybe<CollaborationType>
  judges?: Maybe<Array<Maybe<Judge>>>
  additionalQuestions?: Maybe<Array<Maybe<AdditionalQuestion>>>
}

export type ChallengeEvolution = {
  id: Scalars['ID']
  text?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
}

export type ChallengeEvolutionInput = {
  id?: Maybe<Scalars['ID']>
  text?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
}

export type ChallengeInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  shortDescription: Scalars['String']
  description: Scalars['String']
  cover?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  period?: Maybe<ChallengePeriodInput>
  ratingType: RatingType
  questions?: Maybe<Array<Maybe<ChallengeRatingQuestionInput>>>
  ideaQuestions: Array<Maybe<IdeaQuestionInput>>
  additionalQuestions?: Maybe<Array<Maybe<AdditionalQuestionInput>>>
  challengeEvolution?: Maybe<Array<Maybe<ChallengeEvolutionInput>>>
  collaboration: CollaborationType
  judges?: Maybe<Array<Maybe<JudgeInput>>>
  enablePending?: Maybe<Scalars['Boolean']>
}

export type ChallengePeriod = {
  startDate?: Maybe<Scalars['Date']>
  dueDate?: Maybe<Scalars['Date']>
}

export type ChallengePeriodInput = {
  startDate?: Maybe<Scalars['Date']>
  dueDate?: Maybe<Scalars['Date']>
}

export type ChallengeRatingQuestion = RatingQuestion & {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  scoreType?: Maybe<ScoreType>
  maxRating?: Maybe<Scalars['Float']>
  rubrics?: Maybe<Array<Maybe<Rubric>>>
  id: Scalars['ID']
}

export type ChallengeRatingQuestionInput = {
  id?: Maybe<Scalars['ID']>
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  scoreType: ScoreType
  maxRating: Scalars['Float']
  rubrics?: Maybe<Array<Maybe<RubricInput>>>
}

export enum ChallengeStatus {
  Pending = 'PENDING',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum ClassEnum {
  Idea = 'IDEA',
  Tag = 'TAG',
  User = 'USER',
  Challenge = 'CHALLENGE',
  Challengeevolution = 'CHALLENGEEVOLUTION',
  Evolution = 'EVOLUTION',
}

export enum CollaborationType {
  Crowdsourced = 'CROWDSOURCED',
  Judgeselect = 'JUDGESELECT',
}

export type Collaborator = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  img?: Maybe<Scalars['String']>
}

export type CollaboratorInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  img?: Maybe<Scalars['String']>
}

export type Comment = {
  id: Scalars['ID']
  text: Scalars['String']
  creator?: Maybe<User>
  creationDate?: Maybe<Scalars['Date']>
  tagDict?: Maybe<Scalars['JSON']>
  replyCount?: Maybe<Scalars['Int']>
  attachments?: Maybe<Array<Maybe<Attachment>>>
  cover?: Maybe<Scalars['Int']>
  private?: Maybe<Scalars['Boolean']>
  tag?: Maybe<Array<Maybe<TagComment>>>
}

export type CommentInput = {
  id?: Maybe<Scalars['ID']>
  text: Scalars['String']
  tagDict?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  cover?: Maybe<Scalars['Int']>
  private?: Maybe<Scalars['Boolean']>
  tag?: Maybe<Array<Maybe<TagCommentInput>>>
}

export type CommentPagination = {
  comments?: Maybe<Array<Maybe<Comment>>>
  hasNextPage?: Maybe<Scalars['Boolean']>
}

export type Creator = {
  name: Scalars['String']
  id: Scalars['ID']
  img?: Maybe<Scalars['String']>
}

export type DeleteChallengeResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type DeleteCommentResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type DeleteIdeaResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type DeleteNoteResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type DowFields = {
  LabTechnician?: Maybe<Scalars['String']>
  InitialDate?: Maybe<Scalars['Date']>
  FinalDate?: Maybe<Scalars['Date']>
  MonthAndYear?: Maybe<Scalars['Int']>
  ActivityType?: Maybe<Scalars['String']>
  ProjectId?: Maybe<Scalars['Int']>
  ProjectActivity?: Maybe<Scalars['String']>
  DedicatedTime?: Maybe<Scalars['Float']>
  TotalWorkedHours?: Maybe<Scalars['Int']>
  DedicateTime?: Maybe<Scalars['Int']>
  ProjectTitle?: Maybe<Scalars['String']>
  BusinessSegment?: Maybe<Scalars['String']>
  BusinessPriority?: Maybe<Scalars['String']>
  TechComplex?: Maybe<Scalars['String']>
  Status?: Maybe<Scalars['String']>
  ClosureDate?: Maybe<Scalars['Date']>
  TypeProject?: Maybe<Scalars['String']>
  LabProjectLeader?: Maybe<Scalars['String']>
  TSYD?: Maybe<Scalars['String']>
  CRM?: Maybe<Scalars['Int']>
  NPI?: Maybe<Scalars['Int']>
  DelayReasons?: Maybe<Scalars['String']>
  GeneralComments?: Maybe<Scalars['String']>
  Code?: Maybe<Scalars['Int']>
  RegistrationDate?: Maybe<Scalars['Date']>
  EstimatedProjectDuration?: Maybe<Scalars['Int']>
  TotalProjectDuration?: Maybe<Scalars['Int']>
}

export type DowFieldsInput = {
  LabTechnician?: Maybe<Scalars['String']>
  InitialDate?: Maybe<Scalars['Date']>
  FinalDate?: Maybe<Scalars['Date']>
  MonthAndYear?: Maybe<Scalars['Int']>
  ActivityType?: Maybe<Scalars['String']>
  ProjectId?: Maybe<Scalars['Int']>
  ProjectActivity?: Maybe<Scalars['String']>
  DedicatedTime?: Maybe<Scalars['Float']>
  TotalWorkedHours?: Maybe<Scalars['Int']>
  DedicateTime?: Maybe<Scalars['Int']>
  ProjectTitle?: Maybe<Scalars['String']>
  BusinessSegment?: Maybe<Scalars['String']>
  BusinessPriority?: Maybe<Scalars['String']>
  TechComplex?: Maybe<Scalars['String']>
  Status?: Maybe<Scalars['String']>
  ClosureDate?: Maybe<Scalars['Date']>
  TypeProject?: Maybe<Scalars['String']>
  LabProjectLeader?: Maybe<Scalars['String']>
  TSYD?: Maybe<Scalars['String']>
  CRM?: Maybe<Scalars['Int']>
  NPI?: Maybe<Scalars['Int']>
  DelayReasons?: Maybe<Scalars['String']>
  GeneralComments?: Maybe<Scalars['String']>
  Code?: Maybe<Scalars['Int']>
  RegistrationDate?: Maybe<Scalars['Date']>
  EstimatedProjectDuration?: Maybe<Scalars['Int']>
  TotalProjectDuration?: Maybe<Scalars['Int']>
}

export type FeaturedTag = {
  name: Scalars['String']
  count: Scalars['Int']
}

export enum FileTypes {
  Code = 'CODE',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Ppt = 'PPT',
  File = 'FILE',
}

export type FlagFields = {
  flagged?: Maybe<Scalars['Boolean']>
  reason?: Maybe<Scalars['String']>
}

export type FlagInput = {
  flagged?: Maybe<Scalars['Boolean']>
  reason?: Maybe<Scalars['String']>
}

export type FlagResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type HebFields = {
  statusIdea?: Maybe<Scalars['String']>
}

export type HebFieldsInput = {
  statusIdea?: Maybe<Scalars['String']>
}

export type Idea = {
  tags: Array<Maybe<Scalars['String']>>
  name: Scalars['String']
  id?: Maybe<Scalars['ID']>
  shortDescription?: Maybe<Scalars['String']>
  description: Scalars['String']
  creator?: Maybe<User>
  commentCount?: Maybe<Scalars['Int']>
  noteCount?: Maybe<Scalars['Int']>
  reactionCount?: Maybe<ReactionCount>
  reactions?: Maybe<Array<Maybe<Reaction>>>
  type?: Maybe<Scalars['String']>
  attachments?: Maybe<Array<Maybe<Attachment>>>
  creationDate: Scalars['Date']
  hasAttachment: Scalars['Boolean']
  currentUserReaction?: Maybe<Scalars['String']>
  cover?: Maybe<Scalars['String']>
  challenge?: Maybe<IdeaChallenge>
  stage?: Maybe<Scalars['Int']>
  favorite?: Maybe<Scalars['Boolean']>
  flagged?: Maybe<FlagFields>
  abancaFields?: Maybe<AbancaFields>
  softtekAutoFields?: Maybe<SofttekAutoFields>
  dowFields?: Maybe<DowFields>
  softtekFields?: Maybe<SofttekFields>
  vectorFields?: Maybe<VectorFields>
  hebFields?: Maybe<HebFields>
  adminTags?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type IdeaChallenge = ChallengeData & {
  name: Scalars['String']
  id: Scalars['ID']
  ideaId: Scalars['ID']
  cover?: Maybe<Scalars['String']>
  period?: Maybe<ChallengePeriod>
  ratingType: RatingType
  status: ChallengeStatus
  answered: Scalars['Boolean']
  numberOfVotes: Scalars['Int']
  ratingAverage?: Maybe<Scalars['Float']>
  stage?: Maybe<Scalars['Int']>
  sum?: Maybe<Scalars['Float']>
  ratings?: Maybe<Array<Maybe<IdeaRating>>>
  currentUserRating?: Maybe<UserIdeaRating>
  additionalQuestions?: Maybe<Array<Maybe<AdditionalQuestion>>>
  ideaQuestions: Array<Maybe<IdeaQuestion>>
  enablePending?: Maybe<Scalars['Boolean']>
  collaboration?: Maybe<CollaborationType>
  judges?: Maybe<Array<Maybe<Judge>>>
}

export enum IdeaFilters {
  Type = 'TYPE',
  User = 'USER',
  Tag = 'TAG',
  Recent = 'RECENT',
  Popular = 'POPULAR',
  Suggested = 'SUGGESTED',
  Mine = 'MINE',
  All = 'ALL',
  Challenge = 'CHALLENGE',
  Stage = 'STAGE',
  Daterange = 'DATERANGE',
  Abancacentro = 'ABANCACENTRO',
  Replicable = 'REPLICABLE',
}

export type IdeaInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  shortDescription: Scalars['String']
  description: Scalars['String']
  tags: Array<Maybe<Scalars['String']>>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  type: Scalars['String']
  cover?: Maybe<Scalars['String']>
  abancaFields?: Maybe<AbancaFieldsInput>
  softtekAutoFields?: Maybe<SofttekAutoFieldsInput>
  dowFields?: Maybe<DowFieldsInput>
  vectorFields?: Maybe<VectorFieldsInput>
  softtekFields?: Maybe<SofttekFieldsInput>
  hebFields?: Maybe<HebFieldsInput>
}

export type IdeaPagination = {
  ideas?: Maybe<Array<Maybe<Idea>>>
  hasNextPage?: Maybe<Scalars['Boolean']>
}

export type IdeaQuestion = {
  type: Scalars['String']
  label: Scalars['String']
}

export type IdeaQuestionInput = {
  type: Scalars['String']
  label: Scalars['String']
}

export type IdeaQuestionResponse = {
  label: Scalars['String']
  value: Scalars['String']
}

export type IdeaRating = RatingQuestion & {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  scoreType?: Maybe<ScoreType>
  maxRating?: Maybe<Scalars['Float']>
  rubrics?: Maybe<Array<Maybe<Rubric>>>
  id: Scalars['ID']
  ratingAverage?: Maybe<Scalars['Float']>
  sum?: Maybe<Scalars['Float']>
}

export type IdeasStat = {
  totalIdeas?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['Date']>
}

export type IdeaType = {
  id: Scalars['ID']
  name: Scalars['String']
}

export enum IdeaTypes {
  Tool = 'TOOL',
  Requirement = 'REQUIREMENT',
  Automation = 'AUTOMATION',
}

export type Judge = {
  id: Scalars['ID']
  name: Scalars['String']
  img?: Maybe<Scalars['String']>
}

export type JudgeInput = {
  id: Scalars['ID']
  name: Scalars['String']
  img?: Maybe<Scalars['String']>
}

export type LeadingUserStat = {
  username?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
}

export type Mutation = {
  _empty?: Maybe<Scalars['String']>
  newUser?: Maybe<User>
  updateTagFollow?: Maybe<User>
  updateUserInfo?: Maybe<UserInfoResponse>
  updateUserCodigo?: Maybe<UserInfoResponse>
  updateProfilePic?: Maybe<UpdateProfilePicResponse>
  updateUserFollow?: Maybe<User>
  notificationToken?: Maybe<Scalars['String']>
  markAllAsRead?: Maybe<User>
  changeUserSettings?: Maybe<User>
  featuredIdeas?: Maybe<Array<Maybe<Scalars['String']>>>
  points?: Maybe<Points>
  settings?: Maybe<AppSettings>
  newIdea?: Maybe<Idea>
  updateReaction?: Maybe<Idea>
  newComment?: Maybe<Comment>
  newReply?: Maybe<Reply>
  newReplyNote?: Maybe<ReplyNote>
  updateIdea?: Maybe<Idea>
  updateTagGitHub?: Maybe<Idea>
  updateFavoriteIdea?: Maybe<UpdateFavoriteResponse>
  updateIdeaStage?: Maybe<UpdateStageResponse>
  flagIdea?: Maybe<FlagResponse>
  deleteIdea?: Maybe<DeleteIdeaResponse>
  deleteComment?: Maybe<DeleteCommentResponse>
  deleteNote?: Maybe<DeleteNoteResponse>
  deleteReply?: Maybe<DeleteCommentResponse>
  deleteReplyNote?: Maybe<DeleteNoteResponse>
  rateIdeaForChallenge?: Maybe<Idea>
  newNote?: Maybe<Note>
  newChallenge?: Maybe<Challenge>
  updateChallenge?: Maybe<UpdateChallengeResponse>
  deleteChallenge?: Maybe<DeleteChallengeResponse>
  linkIdeaToChallenge?: Maybe<Challenge>
  unlinkIdeaFromChallenge?: Maybe<UnlinkIdeaFromChallengeResponse>
  proposeIdeaToChallenge?: Maybe<Challenge>
  rejectIdeaFromChallenge?: Maybe<RejectIdeaFromChallengeResponse>
  updateLinkedIdeaStage?: Maybe<UpdateLinkedIdeaStageResponse>
}

export type MutationNewUserArgs = {
  user: UserInput
}

export type MutationUpdateTagFollowArgs = {
  tag: Scalars['String']
}

export type MutationUpdateUserInfoArgs = {
  updateUserInput: UserInfoInput
}

export type MutationUpdateUserCodigoArgs = {
  codigo: Scalars['String']
}

export type MutationUpdateProfilePicArgs = {
  newImg: Scalars['String']
}

export type MutationUpdateUserFollowArgs = {
  userId: Scalars['String']
}

export type MutationNotificationTokenArgs = {
  token?: Maybe<Scalars['String']>
  reset?: Maybe<Scalars['Boolean']>
}

export type MutationChangeUserSettingsArgs = {
  img?: Maybe<Scalars['String']>
  pushNotifications?: Maybe<Scalars['Boolean']>
}

export type MutationFeaturedIdeasArgs = {
  ideas: Array<Maybe<Scalars['String']>>
}

export type MutationPointsArgs = {
  points?: Maybe<PointsInput>
}

export type MutationSettingsArgs = {
  settings: SettingsInput
}

export type MutationNewIdeaArgs = {
  idea: IdeaInput
}

export type MutationUpdateReactionArgs = {
  ideaId: Scalars['ID']
  reaction: Scalars['String']
}

export type MutationNewCommentArgs = {
  ideaId: Scalars['ID']
  comment: CommentInput
}

export type MutationNewReplyArgs = {
  ideaId: Scalars['ID']
  reply: ReplyInput
}

export type MutationNewReplyNoteArgs = {
  ideaId: Scalars['ID']
  reply: ReplyInputNote
}

export type MutationUpdateIdeaArgs = {
  ideaId: Scalars['ID']
  data: UpdateIdeaInput
}

export type MutationUpdateTagGitHubArgs = {
  ideaId: Scalars['ID']
}

export type MutationUpdateFavoriteIdeaArgs = {
  ideaId: Scalars['ID']
  favorite?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateIdeaStageArgs = {
  ideaId: Scalars['ID']
  newStage?: Maybe<Scalars['Int']>
}

export type MutationFlagIdeaArgs = {
  ideaId: Scalars['ID']
  flagInput?: Maybe<FlagInput>
}

export type MutationDeleteIdeaArgs = {
  ideaId: Scalars['ID']
}

export type MutationDeleteCommentArgs = {
  ideaId: Scalars['ID']
  commentId: Scalars['ID']
  commentUser: Scalars['ID']
}

export type MutationDeleteNoteArgs = {
  ideaId: Scalars['ID']
  noteId: Scalars['ID']
  noteUser: Scalars['ID']
}

export type MutationDeleteReplyArgs = {
  ideaId: Scalars['ID']
  commentId: Scalars['ID']
  replyId: Scalars['ID']
  commentUser: Scalars['ID']
}

export type MutationDeleteReplyNoteArgs = {
  ideaId: Scalars['ID']
  noteId: Scalars['ID']
  replyId: Scalars['ID']
  noteUser: Scalars['ID']
}

export type MutationRateIdeaForChallengeArgs = {
  ideaId: Scalars['ID']
  challengeId?: Maybe<Scalars['ID']>
  ratings: Array<Maybe<UserIdeaRatingInput>>
}

export type MutationNewNoteArgs = {
  ideaId: Scalars['ID']
  note: NoteInput
}

export type MutationNewChallengeArgs = {
  challenge: ChallengeInput
}

export type MutationUpdateChallengeArgs = {
  challengeId: Scalars['ID']
  data: UpdateChallengeInput
}

export type MutationDeleteChallengeArgs = {
  challengeId: Scalars['ID']
}

export type MutationLinkIdeaToChallengeArgs = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
  additionalQuestions?: Maybe<AdditionalQuestionAnswers>
}

export type MutationUnlinkIdeaFromChallengeArgs = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type MutationProposeIdeaToChallengeArgs = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type MutationRejectIdeaFromChallengeArgs = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type MutationUpdateLinkedIdeaStageArgs = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
  newStage?: Maybe<Scalars['Int']>
}

export type NameId = {
  name: Scalars['String']
  id: Scalars['ID']
}

export type Note = {
  id: Scalars['ID']
  text: Scalars['String']
  creator?: Maybe<User>
  creationDate?: Maybe<Scalars['Date']>
  replyCount?: Maybe<Scalars['Int']>
}

export type NoteInput = {
  id?: Maybe<Scalars['ID']>
  text: Scalars['String']
}

export type NotePagination = {
  notes?: Maybe<Array<Maybe<Note>>>
  hasNextPage?: Maybe<Scalars['Boolean']>
}

export type Notification = {
  date: Scalars['Date']
  who?: Maybe<User>
  read: Scalars['Boolean']
  type: NotificationType
  target: NotificationTarget
}

export type NotificationTarget = {
  id?: Maybe<Scalars['ID']>
  class?: Maybe<ClassEnum>
  name?: Maybe<Scalars['String']>
}

export enum NotificationType {
  Comment = 'COMMENT',
  TagComment = 'TAG_COMMENT',
  Reaction = 'REACTION',
  Follow = 'FOLLOW',
  Evolution = 'EVOLUTION',
  Linked = 'LINKED',
  Challengeevolution = 'CHALLENGEEVOLUTION',
  Medal = 'MEDAL',
  Flagged = 'FLAGGED',
  Reply = 'REPLY',
  TagIdea = 'TAG_IDEA',
  UserPost = 'USER_POST',
  UserJudge = 'USER_JUDGE',
  AdminLinked = 'ADMIN_LINKED',
  LikeReaction = 'LIKE_REACTION',
  Featured = 'FEATURED',
}

export enum OrderBy {
  Date = 'DATE',
  Rating = 'RATING',
}

export type Points = {
  comment?: Maybe<Scalars['Float']>
  ideaCreation?: Maybe<Scalars['Float']>
  ideaEvolution?: Maybe<Scalars['Float']>
  reaction?: Maybe<Scalars['Float']>
  respondToChallenge?: Maybe<Scalars['Float']>
  ideaFavorite?: Maybe<Scalars['Float']>
  collaboration?: Maybe<Scalars['Float']>
  filtroRevision?: Maybe<Scalars['Float']>
  ideaGanadora?: Maybe<Scalars['Float']>
}

export type PointsInput = {
  comment?: Maybe<Scalars['Float']>
  ideaCreation?: Maybe<Scalars['Float']>
  ideaEvolution?: Maybe<Scalars['Float']>
  reaction?: Maybe<Scalars['Float']>
  respondToChallenge?: Maybe<Scalars['Float']>
  ideaFavorite?: Maybe<Scalars['Float']>
  collaboration?: Maybe<Scalars['Float']>
  filtroRevision?: Maybe<Scalars['Float']>
  ideaGanadora?: Maybe<Scalars['Float']>
}

export type Query = {
  _empty?: Maybe<Scalars['String']>
  autocomplete?: Maybe<Scalars['JSON']>
  me?: Maybe<User>
  sas?: Maybe<Sas>
  user?: Maybe<User>
  stats?: Maybe<Stats>
  points?: Maybe<Points>
  appSettings?: Maybe<AppSettings>
  idea?: Maybe<Idea>
  ideaReactions?: Maybe<Array<Maybe<Reaction>>>
  ideaComments?: Maybe<CommentPagination>
  ideaNotes?: Maybe<NotePagination>
  ideaStatistics?: Maybe<Array<Maybe<Statistic>>>
  ideaChallengeRatings?: Maybe<Array<Maybe<UserIdeaRating>>>
  commentReplies?: Maybe<ReplyPagination>
  noteReplies?: Maybe<ReplyNotePagination>
  ideas?: Maybe<IdeaPagination>
  featuredTags: Array<Maybe<FeaturedTag>>
  replicableIdeas: Array<Maybe<ReplicableTag>>
  featuredIdeas: Array<Maybe<Idea>>
  ideasDestacadas: Array<Maybe<Idea>>
  challenge?: Maybe<Challenge>
  challenges?: Maybe<Array<Maybe<Challenge>>>
}

export type QueryUserArgs = {
  userId: Scalars['ID']
}

export type QueryIdeaArgs = {
  ideaId: Scalars['ID']
}

export type QueryIdeaReactionsArgs = {
  ideaId: Scalars['ID']
}

export type QueryIdeaCommentsArgs = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type QueryIdeaNotesArgs = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type QueryIdeaStatisticsArgs = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
}

export type QueryIdeaChallengeRatingsArgs = {
  ideaId: Scalars['ID']
  challengeId: Scalars['ID']
}

export type QueryCommentRepliesArgs = {
  ideaId: Scalars['ID']
  commentId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type QueryNoteRepliesArgs = {
  ideaId: Scalars['ID']
  noteId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type QueryIdeasArgs = {
  filter?: Maybe<IdeaFilters>
  value?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
  orderBy?: Maybe<OrderBy>
  replicable?: Maybe<Scalars['Boolean']>
}

export type QueryChallengeArgs = {
  challengeId: Scalars['ID']
}

export type Range = {
  min: Scalars['Float']
  max: Scalars['Float']
}

export type RangeInput = {
  min: Scalars['Float']
  max: Scalars['Float']
}

export type RatingQuestion = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  scoreType?: Maybe<ScoreType>
  maxRating?: Maybe<Scalars['Float']>
  rubrics?: Maybe<Array<Maybe<Rubric>>>
  id: Scalars['ID']
}

export enum RatingType {
  Stars = 'STARS',
  Slider = 'SLIDER',
}

export type Reaction = {
  creationDate: Scalars['Date']
  reaction: Scalars['String']
  updatedAt: Scalars['Date']
  user?: Maybe<User>
}

export type ReactionCount = {
  love?: Maybe<Scalars['Int']>
  like?: Maybe<Scalars['Int']>
  dislike?: Maybe<Scalars['Int']>
}

export type RejectIdeaFromChallengeResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type ReplicableTag = {
  type: Scalars['String']
  count: Scalars['Int']
}

export type Reply = {
  id: Scalars['ID']
  replyId?: Maybe<Scalars['ID']>
  text: Scalars['String']
  creator?: Maybe<User>
  creationDate?: Maybe<Scalars['Date']>
  isReply?: Maybe<Scalars['Boolean']>
  tagDict?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Array<Maybe<Attachment>>>
  cover?: Maybe<Scalars['Int']>
  private?: Maybe<Scalars['Boolean']>
  replyTo?: Maybe<ReplyUserTag>
  tag?: Maybe<Array<Maybe<TagComment>>>
}

export type ReplyInput = {
  id?: Maybe<Scalars['ID']>
  replyId?: Maybe<Scalars['ID']>
  text: Scalars['String']
  tagDict?: Maybe<Scalars['JSON']>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  cover?: Maybe<Scalars['Int']>
  private?: Maybe<Scalars['Boolean']>
  replyTo?: Maybe<ReplyUserTagInput>
  tag?: Maybe<Array<Maybe<TagCommentInput>>>
}

export type ReplyInputNote = {
  id?: Maybe<Scalars['ID']>
  replyId?: Maybe<Scalars['ID']>
  text: Scalars['String']
  replyTo?: Maybe<ReplyUserTagInput>
}

export type ReplyNote = {
  id: Scalars['ID']
  replyId?: Maybe<Scalars['ID']>
  text: Scalars['String']
  creator?: Maybe<User>
  creationDate?: Maybe<Scalars['Date']>
  isReply?: Maybe<Scalars['Boolean']>
  replyTo?: Maybe<ReplyUserTag>
}

export type ReplyNotePagination = {
  replies?: Maybe<Array<Maybe<ReplyNote>>>
  hasNextPage?: Maybe<Scalars['Boolean']>
}

export type ReplyPagination = {
  replies?: Maybe<Array<Maybe<Reply>>>
  hasNextPage?: Maybe<Scalars['Boolean']>
}

export type ReplyUserTag = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type ReplyUserTagInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export enum Role {
  Admin = 'ADMIN',
  Reviewer = 'REVIEWER',
  User = 'USER',
  Unknown = 'UNKNOWN',
  Me = 'ME',
}

export type Rubric = {
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  range?: Maybe<Range>
  color?: Maybe<Scalars['String']>
}

export type RubricInput = {
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  range?: Maybe<RangeInput>
  color?: Maybe<Scalars['String']>
}

export type Sas = {
  token: Scalars['String']
  expiresBy?: Maybe<Scalars['Float']>
}

export enum ScoreType {
  Score = 'SCORE',
  Ratio = 'RATIO',
}

export type SettingsInput = {
  points?: Maybe<PointsInput>
  ideaTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  featuredIdeas?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SofttekAutoFields = {
  market?: Maybe<Scalars['String']>
  industry?: Maybe<Scalars['String']>
  account?: Maybe<Scalars['String']>
  service?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  replicable?: Maybe<Scalars['Boolean']>
  repositoryCode?: Maybe<Scalars['String']>
  responsible?: Maybe<Scalars['String']>
  licenseRequired?: Maybe<Scalars['Boolean']>
  propietary?: Maybe<Scalars['String']>
  automationType?: Maybe<Scalars['String']>
  complexity?: Maybe<Scalars['String']>
}

export type SofttekAutoFieldsInput = {
  market?: Maybe<Scalars['String']>
  industry?: Maybe<Scalars['String']>
  account?: Maybe<Scalars['String']>
  service?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  replicable?: Maybe<Scalars['Boolean']>
  repositoryCode?: Maybe<Scalars['String']>
  responsible?: Maybe<Scalars['String']>
  licenseRequired?: Maybe<Scalars['Boolean']>
  propietary?: Maybe<Scalars['String']>
  automationType?: Maybe<Scalars['String']>
  complexity?: Maybe<Scalars['String']>
}

export type SofttekCanvas = {
  Categoria?: Maybe<Scalars['String']>
  Valor?: Maybe<Scalars['Int']>
  Competencias?: Maybe<Scalars['Int']>
}

export type SofttekCanvasInput = {
  Categoria?: Maybe<Scalars['String']>
  Valor?: Maybe<Scalars['Int']>
  Competencias?: Maybe<Scalars['Int']>
}

export type SofttekFields = {
  Categorias?: Maybe<Array<Maybe<SofttekCanvas>>>
}

export type SofttekFieldsInput = {
  Categorias?: Maybe<Array<Maybe<SofttekCanvasInput>>>
}

export type Staff = {
  name: Scalars['String']
  id: Scalars['ID']
}

export type StaffInput = {
  name: Scalars['String']
  id: Scalars['ID']
}

export type Statistic = {
  id: Scalars['ID']
  name: Scalars['String']
  label: Array<Maybe<Scalars['String']>>
  description: Scalars['String']
  type: StatType
  value?: Maybe<Array<Maybe<StatsCoordinates>>>
  tag?: Maybe<Scalars['String']>
}

export type Stats = {
  users?: Maybe<Array<Maybe<UserStat>>>
  ideas?: Maybe<Array<Maybe<IdeasStat>>>
  leaderboard?: Maybe<Array<Maybe<LeadingUserStat>>>
  types?: Maybe<Array<Maybe<TypeStat>>>
}

export type StatsCoordinates = {
  x?: Maybe<Scalars['String']>
  y?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export enum StatType {
  Pie = 'PIE',
  Bar = 'BAR',
  Line = 'LINE',
  Polar = 'POLAR',
  Radar = 'RADAR',
}

export type TagComment = {
  id: Scalars['String']
  name: Scalars['String']
}

export type TagCommentInput = {
  id: Scalars['String']
  name: Scalars['String']
}

export type TypeStat = {
  type?: Maybe<Scalars['String']>
  ideas?: Maybe<Scalars['Int']>
}

export type UnlinkIdeaFromChallengeResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type UpdateChallengeInput = {
  name?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cover?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  period?: Maybe<ChallengePeriodInput>
  ratingType?: Maybe<RatingType>
  questions?: Maybe<Array<Maybe<ChallengeRatingQuestionInput>>>
  ideaQuestions?: Maybe<Array<Maybe<IdeaQuestionInput>>>
  challengeEvolution?: Maybe<Array<Maybe<ChallengeEvolutionInput>>>
  additionalQuestions?: Maybe<Array<Maybe<AdditionalQuestionInput>>>
  collaboration?: Maybe<CollaborationType>
  judges?: Maybe<Array<Maybe<JudgeInput>>>
  enablePending?: Maybe<Scalars['Boolean']>
}

export type UpdateChallengeResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type UpdateFavoriteResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type UpdateIdeaInput = {
  name?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  shortDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>
  type?: Maybe<Scalars['String']>
  cover?: Maybe<Scalars['String']>
  abancaFields?: Maybe<AbancaFieldsInput>
  softtekAutoFields?: Maybe<SofttekAutoFieldsInput>
  dowFields?: Maybe<DowFieldsInput>
  vectorFields?: Maybe<VectorFieldsInput>
  softtekFields?: Maybe<SofttekFieldsInput>
  hebFields?: Maybe<HebFieldsInput>
  adminTags?: Maybe<Array<Maybe<Scalars['String']>>>
  stage?: Maybe<Scalars['Int']>
}

export type UpdateLinkedIdeaStageResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type UpdateProfilePicResponse = {
  message: Scalars['String']
  userId?: Maybe<Scalars['ID']>
}

export type UpdateStageResponse = {
  message: Scalars['String']
  id: Scalars['ID']
}

export type User = {
  name: Scalars['String']
  id: Scalars['ID']
  img?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['ID']>
  isAdmin?: Maybe<Scalars['Boolean']>
  officeLocation?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  departmentName?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  points?: Maybe<Scalars['Float']>
  email?: Maybe<Scalars['String']>
  favoriteTags?: Maybe<Array<Maybe<Scalars['String']>>>
  followers?: Maybe<Array<Maybe<User>>>
  followersIds?: Maybe<Array<Maybe<Scalars['String']>>>
  usersFollowing?: Maybe<Array<Maybe<User>>>
  usersFollowingIds?: Maybe<Array<Maybe<Scalars['String']>>>
  reactedIdeas?: Maybe<Array<Maybe<Idea>>>
  sas?: Maybe<Sas>
  notifications?: Maybe<Array<Maybe<Notification>>>
  notificationToken?: Maybe<Scalars['String']>
  codigo?: Maybe<Scalars['String']>
}

export type UserIdeaRating = {
  id: Scalars['ID']
  ratings: Array<Maybe<UserRating>>
  sender: User
  ratedAt: Scalars['Date']
  average?: Maybe<Scalars['Float']>
}

export type UserIdeaRatingInput = {
  questionId: Scalars['ID']
  rating: Scalars['Float']
}

export type UserInfoInput = {
  officeLocation: Scalars['String']
  role: Scalars['String']
  department: Scalars['String']
  employeeId: Scalars['String']
}

export type UserInfoResponse = {
  message: Scalars['String']
  userId?: Maybe<Scalars['ID']>
}

export type UserInput = {
  img?: Maybe<Scalars['String']>
}

export type UserRating = {
  questionId: Scalars['ID']
  rating: Scalars['Float']
}

export type UserStat = {
  totalUsers?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['Date']>
}

export type Vector = {
  RPA?: Maybe<Scalars['Int']>
  BI?: Maybe<Scalars['Int']>
  RPApercentage?: Maybe<Scalars['Int']>
  BIpercentage?: Maybe<Scalars['Int']>
}

export type VectorFields = {
  responsable?: Maybe<Scalars['String']>
  departamento?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  manual?: Maybe<Scalars['String']>
  frecuencia?: Maybe<Scalars['String']>
  volumetrias?: Maybe<Scalars['Int']>
  volumetriasUM?: Maybe<Scalars['String']>
  duracion?: Maybe<Scalars['Int']>
  duracionUM?: Maybe<Scalars['String']>
  picosOperativos?: Maybe<Scalars['Boolean']>
  informacionPicos?: Maybe<Scalars['String']>
  aspectos?: Maybe<Array<Maybe<Scalars['String']>>>
  consistencia?: Maybe<Scalars['String']>
  reglas?: Maybe<Scalars['String']>
  datosEstandarizados?: Maybe<Scalars['String']>
  datosLegibles?: Maybe<Scalars['String']>
  procedimientoHabitual?: Maybe<Scalars['String']>
  cambiosProceso?: Maybe<Scalars['String']>
  cambioSistema?: Maybe<Scalars['String']>
  NoAplicaciones?: Maybe<Scalars['Int']>
  scores?: Maybe<Vector>
}

export type VectorFieldsInput = {
  responsable?: Maybe<Scalars['String']>
  departamento?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  manual?: Maybe<Scalars['String']>
  frecuencia?: Maybe<Scalars['String']>
  volumetrias?: Maybe<Scalars['Int']>
  volumetriasUM?: Maybe<Scalars['String']>
  duracion?: Maybe<Scalars['Int']>
  duracionUM?: Maybe<Scalars['String']>
  picosOperativos?: Maybe<Scalars['Boolean']>
  informacionPicos?: Maybe<Scalars['String']>
  aspectos?: Maybe<Array<Maybe<Scalars['String']>>>
  consistencia?: Maybe<Scalars['String']>
  reglas?: Maybe<Scalars['String']>
  datosEstandarizados?: Maybe<Scalars['String']>
  datosLegibles?: Maybe<Scalars['String']>
  procedimientoHabitual?: Maybe<Scalars['String']>
  cambiosProceso?: Maybe<Scalars['String']>
  cambioSistema?: Maybe<Scalars['String']>
  NoAplicaciones?: Maybe<Scalars['Int']>
  scores?: Maybe<VectorInput>
}

export type VectorInput = {
  RPA?: Maybe<Scalars['Int']>
  BI?: Maybe<Scalars['Int']>
  RPApercentage?: Maybe<Scalars['Int']>
  BIpercentage?: Maybe<Scalars['Int']>
}
export type AutocompleteQueryVariables = {}

export type AutocompleteQuery = { __typename?: 'Query' } & Pick<
  Query,
  'autocomplete'
>

export type NewIdeaMutationVariables = {
  idea: IdeaInput
}

export type NewIdeaMutation = { __typename?: 'Mutation' } & {
  newIdea: Maybe<
    { __typename?: 'Idea' } & Pick<
      Idea,
      | 'id'
      | 'name'
      | 'shortDescription'
      | 'cover'
      | 'description'
      | 'tags'
      | 'type'
    > & {
        attachments: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attachment' } & Pick<
                Attachment,
                'name' | 'type' | 'url'
              >
            >
          >
        >
      }
  >
}

export type DeleteIdeaMutationVariables = {
  ideaId: Scalars['ID']
}

export type DeleteIdeaMutation = { __typename?: 'Mutation' } & {
  deleteIdea: Maybe<
    { __typename?: 'DeleteIdeaResponse' } & Pick<
      DeleteIdeaResponse,
      'message' | 'id'
    >
  >
}

export type NewUserMutationVariables = {
  user: UserInput
}

export type NewUserMutation = { __typename?: 'Mutation' } & {
  newUser: Maybe<{ __typename?: 'User' } & Pick<User, 'img'>>
}

export type IdeasQueryVariables = {
  filter?: Maybe<IdeaFilters>
  cursor?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['String']>
  replicable?: Maybe<Scalars['Boolean']>
}

export type IdeasQuery = { __typename?: 'Query' } & {
  ideas: Maybe<
    { __typename?: 'IdeaPagination' } & Pick<IdeaPagination, 'hasNextPage'> & {
        ideas: Maybe<
          Array<
            Maybe<
              { __typename?: 'Idea' } & Pick<Idea, 'currentUserReaction'> & {
                  challenge: Maybe<
                    { __typename: 'IdeaChallenge' } & Pick<
                      IdeaChallenge,
                      | 'name'
                      | 'numberOfVotes'
                      | 'id'
                      | 'ratingType'
                      | 'ratingAverage'
                      | 'cover'
                      | 'stage'
                    > & {
                        period: Maybe<
                          { __typename?: 'ChallengePeriod' } & Pick<
                            ChallengePeriod,
                            'startDate' | 'dueDate'
                          >
                        >
                        additionalQuestions: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'AdditionalQuestion' } & Pick<
                                AdditionalQuestion,
                                'text' | 'answer'
                              >
                            >
                          >
                        >
                        ratings: Maybe<
                          Array<
                            Maybe<
                              { __typename: 'IdeaRating' } & Pick<
                                IdeaRating,
                                | 'title'
                                | 'sum'
                                | 'id'
                                | 'maxRating'
                                | 'ratingAverage'
                              >
                            >
                          >
                        >
                        currentUserRating: Maybe<
                          { __typename?: 'UserIdeaRating' } & {
                            ratings: Array<
                              Maybe<
                                { __typename: 'UserRating' } & Pick<
                                  UserRating,
                                  'rating' | 'questionId'
                                >
                              >
                            >
                          }
                        >
                      }
                  >
                  vectorFields: Maybe<
                    { __typename?: 'VectorFields' } & {
                      scores: Maybe<
                        { __typename?: 'Vector' } & Pick<
                          Vector,
                          'RPA' | 'BI' | 'RPApercentage' | 'BIpercentage'
                        >
                      >
                    }
                  >
                } & IdeaPartsFragment
            >
          >
        >
      }
  >
}

export type IdeaPartsFragment = { __typename: 'Idea' } & Pick<
  Idea,
  | 'name'
  | 'shortDescription'
  | 'description'
  | 'id'
  | 'cover'
  | 'type'
  | 'creationDate'
  | 'tags'
  | 'stage'
  | 'favorite'
  | 'hasAttachment'
> & {
    creator: Maybe<
      { __typename: 'User' } & Pick<User, 'id' | 'name' | 'img' | 'email'>
    >
    attachments: Maybe<
      Array<
        Maybe<
          { __typename?: 'Attachment' } & Pick<
            Attachment,
            'name' | 'type' | 'url'
          >
        >
      >
    >
    reactionCount: Maybe<
      { __typename: 'ReactionCount' } & Pick<
        ReactionCount,
        'love' | 'like' | 'dislike'
      >
    >
  }

export type IdeasDestacadasQueryVariables = {}

export type IdeasDestacadasQuery = { __typename?: 'Query' } & {
  ideasDestacadas: Array<Maybe<{ __typename?: 'Idea' } & IdeaPartsFragment>>
}

export type IdeaQueryVariables = {
  ideaId: Scalars['ID']
}

export type IdeaQuery = { __typename?: 'Query' } & {
  idea: Maybe<
    { __typename?: 'Idea' } & Pick<
      Idea,
      'commentCount' | 'noteCount' | 'currentUserReaction' | 'adminTags'
    > & {
        softtekFields: Maybe<
          { __typename?: 'SofttekFields' } & {
            Categorias: Maybe<
              Array<
                Maybe<
                  { __typename: 'SofttekCanvas' } & Pick<
                    SofttekCanvas,
                    'Categoria' | 'Valor' | 'Competencias'
                  >
                >
              >
            >
          }
        >
        softtekAutoFields: Maybe<
          { __typename: 'SofttekAutoFields' } & Pick<
            SofttekAutoFields,
            | 'market'
            | 'industry'
            | 'account'
            | 'service'
            | 'platform'
            | 'replicable'
            | 'repositoryCode'
            | 'responsible'
            | 'licenseRequired'
            | 'propietary'
            | 'automationType'
            | 'complexity'
          >
        >
        abancaFields: Maybe<
          { __typename?: 'ABANCAFields' } & Pick<
            AbancaFields,
            | 'periodicidad'
            | 'tiempoDedicado'
            | 'volumenEstimado'
            | 'tiempoTarea'
            | 'tiempoTareaVolumen'
            | 'riesgoOperacion'
            | 'ahorroCostes'
            | 'fechaMax'
            | 'peticionCER'
            | 'departmentTitle'
            | 'departmentValue'
            | 'departmentLevels'
          > & {
              collaborators: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Collaborator' } & Pick<
                      Collaborator,
                      'id' | 'name' | 'img'
                    >
                  >
                >
              >
            }
        >
        vectorFields: Maybe<
          { __typename?: 'VectorFields' } & Pick<
            VectorFields,
            | 'responsable'
            | 'departamento'
            | 'area'
            | 'manual'
            | 'frecuencia'
            | 'volumetrias'
            | 'volumetriasUM'
            | 'duracion'
            | 'duracionUM'
            | 'picosOperativos'
            | 'informacionPicos'
            | 'aspectos'
            | 'consistencia'
            | 'reglas'
            | 'datosEstandarizados'
            | 'datosLegibles'
            | 'procedimientoHabitual'
            | 'cambiosProceso'
            | 'cambioSistema'
            | 'NoAplicaciones'
          > & {
              scores: Maybe<
                { __typename?: 'Vector' } & Pick<
                  Vector,
                  'RPA' | 'BI' | 'RPApercentage' | 'BIpercentage'
                >
              >
            }
        >
        dowFields: Maybe<
          { __typename: 'DOWFields' } & Pick<
            DowFields,
            | 'LabTechnician'
            | 'InitialDate'
            | 'FinalDate'
            | 'MonthAndYear'
            | 'ActivityType'
            | 'ProjectId'
            | 'ProjectActivity'
            | 'DedicatedTime'
            | 'TotalWorkedHours'
            | 'DedicateTime'
            | 'ProjectTitle'
            | 'BusinessSegment'
            | 'BusinessPriority'
            | 'TechComplex'
            | 'Status'
            | 'ClosureDate'
            | 'TypeProject'
            | 'LabProjectLeader'
            | 'TSYD'
            | 'CRM'
            | 'NPI'
            | 'DelayReasons'
            | 'GeneralComments'
            | 'Code'
            | 'RegistrationDate'
            | 'EstimatedProjectDuration'
            | 'TotalProjectDuration'
          >
        >
        hebFields: Maybe<
          { __typename?: 'HEBFields' } & Pick<HebFields, 'statusIdea'>
        >
        flagged: Maybe<
          { __typename?: 'FlagFields' } & Pick<FlagFields, 'flagged' | 'reason'>
        >
        challenge: Maybe<
          { __typename: 'IdeaChallenge' } & Pick<
            IdeaChallenge,
            | 'name'
            | 'id'
            | 'ratingType'
            | 'status'
            | 'ratingAverage'
            | 'cover'
            | 'enablePending'
            | 'collaboration'
          > & {
              period: Maybe<
                { __typename?: 'ChallengePeriod' } & Pick<
                  ChallengePeriod,
                  'startDate' | 'dueDate'
                >
              >
              ratings: Maybe<
                Array<
                  Maybe<
                    { __typename: 'IdeaRating' } & Pick<
                      IdeaRating,
                      | 'title'
                      | 'description'
                      | 'scoreType'
                      | 'maxRating'
                      | 'id'
                      | 'ratingAverage'
                      | 'sum'
                    > & {
                        rubrics: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Rubric' } & Pick<
                                Rubric,
                                'title' | 'description' | 'color'
                              > & {
                                  range: Maybe<
                                    { __typename?: 'Range' } & Pick<
                                      Range,
                                      'min' | 'max'
                                    >
                                  >
                                }
                            >
                          >
                        >
                      }
                  >
                >
              >
              currentUserRating: Maybe<
                { __typename?: 'UserIdeaRating' } & {
                  ratings: Array<
                    Maybe<
                      { __typename: 'UserRating' } & Pick<
                        UserRating,
                        'rating' | 'questionId'
                      >
                    >
                  >
                }
              >
              judges: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Judge' } & Pick<
                      Judge,
                      'id' | 'name' | 'img'
                    >
                  >
                >
              >
            }
        >
      } & IdeaPartsFragment
  >
}

export type IdeaReactionsQueryVariables = {
  ideaId: Scalars['ID']
}

export type IdeaReactionsQuery = { __typename?: 'Query' } & {
  ideaReactions: Maybe<
    Array<
      Maybe<
        { __typename?: 'Reaction' } & Pick<
          Reaction,
          'reaction' | 'updatedAt'
        > & { user: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>> }
      >
    >
  >
}

export type UpdateIdeaReactionMutationVariables = {
  ideaId: Scalars['ID']
  reaction: Scalars['String']
}

export type UpdateIdeaReactionMutation = { __typename?: 'Mutation' } & {
  updateReaction: Maybe<
    { __typename?: 'Idea' } & Pick<Idea, 'currentUserReaction'> &
      IdeaPartsFragment
  >
}

export type FlagIdeaMutationVariables = {
  ideaId: Scalars['ID']
  flagInput: FlagInput
}

export type FlagIdeaMutation = { __typename?: 'Mutation' } & {
  flagIdea: Maybe<
    { __typename?: 'FlagResponse' } & Pick<FlagResponse, 'message' | 'id'>
  >
}

export type SasQueryVariables = {}

export type SasQuery = { __typename?: 'Query' } & {
  sas: Maybe<{ __typename?: 'SAS' } & Pick<Sas, 'token' | 'expiresBy'>>
}

export type MePartsFragment = { __typename?: 'User' } & Pick<
  User,
  | 'name'
  | 'id'
  | 'img'
  | 'uid'
  | 'isAdmin'
  | 'officeLocation'
  | 'role'
  | 'department'
  | 'employeeId'
  | 'points'
  | 'email'
  | 'favoriteTags'
  | 'usersFollowingIds'
  | 'codigo'
> & {
    usersFollowing: Maybe<
      Array<Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'id'>>>
    >
    reactedIdeas: Maybe<
      Array<Maybe<{ __typename?: 'Idea' } & Pick<Idea, 'name' | 'id'>>>
    >
    notifications: Maybe<
      Array<
        Maybe<
          { __typename?: 'Notification' } & Pick<
            Notification,
            'type' | 'date' | 'read'
          > & {
              who: Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'id'>>
              target: { __typename?: 'NotificationTarget' } & Pick<
                NotificationTarget,
                'class' | 'id' | 'name'
              >
            }
        >
      >
    >
  }

export type MeQueryVariables = {}

export type MeQuery = { __typename?: 'Query' } & {
  me: Maybe<
    { __typename?: 'User' } & {
      sas: Maybe<{ __typename?: 'SAS' } & Pick<Sas, 'token' | 'expiresBy'>>
    } & MePartsFragment
  >
}

export type UpdateIdeaMutationVariables = {
  ideaId: Scalars['ID']
  data: UpdateIdeaInput
}

export type UpdateIdeaMutation = { __typename?: 'Mutation' } & {
  updateIdea: Maybe<
    { __typename?: 'Idea' } & Pick<Idea, 'name' | 'description' | 'id'>
  >
}

export type UpdateTagGitHubMutationVariables = {
  ideaId: Scalars['ID']
}

export type UpdateTagGitHubMutation = { __typename?: 'Mutation' } & {
  updateTagGitHub: Maybe<{ __typename?: 'Idea' } & Pick<Idea, 'tags'>>
}

export type NewCommentMutationVariables = {
  ideaId: Scalars['ID']
  comment: CommentInput
}

export type NewCommentMutation = { __typename?: 'Mutation' } & {
  newComment: Maybe<{ __typename?: 'Comment' } & CommentPartsFragment>
}

export type NewNoteMutationVariables = {
  ideaId: Scalars['ID']
  note: NoteInput
}

export type NewNoteMutation = { __typename?: 'Mutation' } & {
  newNote: Maybe<{ __typename?: 'Note' } & NotePartsFragment>
}

export type DeleteCommentMutationVariables = {
  ideaId: Scalars['ID']
  commentId: Scalars['ID']
  commentUser: Scalars['ID']
}

export type DeleteCommentMutation = { __typename?: 'Mutation' } & {
  deleteComment: Maybe<
    { __typename?: 'DeleteCommentResponse' } & Pick<
      DeleteCommentResponse,
      'message' | 'id'
    >
  >
}

export type DeleteNoteMutationVariables = {
  ideaId: Scalars['ID']
  noteId: Scalars['ID']
  noteUser: Scalars['ID']
}

export type DeleteNoteMutation = { __typename?: 'Mutation' } & {
  deleteNote: Maybe<
    { __typename?: 'DeleteNoteResponse' } & Pick<
      DeleteNoteResponse,
      'message' | 'id'
    >
  >
}

export type UpdateFavoriteIdeaMutationVariables = {
  ideaId: Scalars['ID']
  favorite: Scalars['Boolean']
}

export type UpdateFavoriteIdeaMutation = { __typename?: 'Mutation' } & {
  updateFavoriteIdea: Maybe<
    { __typename?: 'UpdateFavoriteResponse' } & Pick<
      UpdateFavoriteResponse,
      'message' | 'id'
    >
  >
}

export type UpdateIdeaStageMutationVariables = {
  ideaId: Scalars['ID']
  newStage: Scalars['Int']
}

export type UpdateIdeaStageMutation = { __typename?: 'Mutation' } & {
  updateIdeaStage: Maybe<
    { __typename?: 'UpdateStageResponse' } & Pick<
      UpdateStageResponse,
      'message' | 'id'
    >
  >
}

export type UpdateProfilePicMutationVariables = {
  newImg: Scalars['String']
}

export type UpdateProfilePicMutation = { __typename?: 'Mutation' } & {
  updateProfilePic: Maybe<
    { __typename?: 'UpdateProfilePicResponse' } & Pick<
      UpdateProfilePicResponse,
      'message' | 'userId'
    >
  >
}

export type NewReplyMutationVariables = {
  ideaId: Scalars['ID']
  reply: ReplyInput
}

export type NewReplyMutation = { __typename?: 'Mutation' } & {
  newReply: Maybe<
    { __typename?: 'Reply' } & Pick<
      Reply,
      'id' | 'replyId' | 'text' | 'creationDate' | 'isReply'
    > & {
        creator: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>
        replyTo: Maybe<
          { __typename?: 'ReplyUserTag' } & Pick<ReplyUserTag, 'id' | 'name'>
        >
        tag: Maybe<
          Array<
            Maybe<
              { __typename?: 'TagComment' } & Pick<TagComment, 'id' | 'name'>
            >
          >
        >
      }
  >
}

export type NewReplyNoteMutationVariables = {
  ideaId: Scalars['ID']
  reply: ReplyInputNote
}

export type NewReplyNoteMutation = { __typename?: 'Mutation' } & {
  newReplyNote: Maybe<
    { __typename?: 'ReplyNote' } & Pick<
      ReplyNote,
      'id' | 'replyId' | 'text' | 'creationDate' | 'isReply'
    > & {
        creator: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>
        replyTo: Maybe<
          { __typename?: 'ReplyUserTag' } & Pick<ReplyUserTag, 'id' | 'name'>
        >
      }
  >
}

export type CommentPartsFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  'id' | 'text' | 'creationDate' | 'replyCount' | 'cover' | 'private'
> & {
    creator: Maybe<{ __typename: 'User' } & Pick<User, 'id' | 'name' | 'img'>>
    attachments: Maybe<
      Array<
        Maybe<
          { __typename?: 'Attachment' } & Pick<
            Attachment,
            'name' | 'type' | 'url'
          >
        >
      >
    >
    tag: Maybe<
      Array<
        Maybe<{ __typename?: 'TagComment' } & Pick<TagComment, 'id' | 'name'>>
      >
    >
  }

export type NotePartsFragment = { __typename?: 'Note' } & Pick<
  Note,
  'id' | 'text' | 'creationDate' | 'replyCount'
> & {
    creator: Maybe<{ __typename: 'User' } & Pick<User, 'id' | 'name' | 'img'>>
  }

export type StatisticPartsFragment = { __typename?: 'Statistic' } & Pick<
  Statistic,
  'id' | 'name' | 'description' | 'type' | 'label' | 'tag'
> & {
    value: Maybe<
      Array<
        Maybe<
          { __typename?: 'StatsCoordinates' } & Pick<
            StatsCoordinates,
            'x' | 'y'
          >
        >
      >
    >
  }

export type ReplyPartsFragment = { __typename?: 'Reply' } & Pick<
  Reply,
  'id' | 'replyId' | 'text' | 'creationDate' | 'isReply' | 'cover' | 'private'
> & {
    creator: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'img'>>
    attachments: Maybe<
      Array<
        Maybe<
          { __typename?: 'Attachment' } & Pick<
            Attachment,
            'name' | 'type' | 'url'
          >
        >
      >
    >
    replyTo: Maybe<
      { __typename?: 'ReplyUserTag' } & Pick<ReplyUserTag, 'id' | 'name'>
    >
    tag: Maybe<
      Array<
        Maybe<{ __typename?: 'TagComment' } & Pick<TagComment, 'id' | 'name'>>
      >
    >
  }

export type ReplyNotePartsFragment = { __typename?: 'ReplyNote' } & Pick<
  ReplyNote,
  'id' | 'replyId' | 'text' | 'creationDate' | 'isReply'
> & {
    creator: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'img'>>
    replyTo: Maybe<
      { __typename?: 'ReplyUserTag' } & Pick<ReplyUserTag, 'id' | 'name'>
    >
  }

export type IdeaCommentsQueryVariables = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type IdeaCommentsQuery = { __typename?: 'Query' } & {
  ideaComments: Maybe<
    { __typename?: 'CommentPagination' } & Pick<
      CommentPagination,
      'hasNextPage'
    > & {
        comments: Maybe<
          Array<Maybe<{ __typename?: 'Comment' } & CommentPartsFragment>>
        >
      }
  >
}

export type IdeaNotesQueryVariables = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type IdeaNotesQuery = { __typename?: 'Query' } & {
  ideaNotes: Maybe<
    { __typename?: 'NotePagination' } & Pick<NotePagination, 'hasNextPage'> & {
        notes: Maybe<Array<Maybe<{ __typename?: 'Note' } & NotePartsFragment>>>
      }
  >
}

export type IdeaStatisticsQueryVariables = {
  ideaId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
}

export type IdeaStatisticsQuery = { __typename?: 'Query' } & {
  ideaStatistics: Maybe<
    Array<Maybe<{ __typename?: 'Statistic' } & StatisticPartsFragment>>
  >
}

export type CommentRepliesQueryVariables = {
  ideaId: Scalars['ID']
  commentId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type CommentRepliesQuery = { __typename?: 'Query' } & {
  commentReplies: Maybe<
    { __typename?: 'ReplyPagination' } & Pick<
      ReplyPagination,
      'hasNextPage'
    > & {
        replies: Maybe<
          Array<Maybe<{ __typename?: 'Reply' } & ReplyPartsFragment>>
        >
      }
  >
}

export type NoteRepliesQueryVariables = {
  ideaId: Scalars['ID']
  noteId: Scalars['ID']
  size?: Maybe<Scalars['Int']>
  cursor?: Maybe<Scalars['String']>
}

export type NoteRepliesQuery = { __typename?: 'Query' } & {
  noteReplies: Maybe<
    { __typename?: 'ReplyNotePagination' } & Pick<
      ReplyNotePagination,
      'hasNextPage'
    > & {
        replies: Maybe<
          Array<Maybe<{ __typename?: 'ReplyNote' } & ReplyNotePartsFragment>>
        >
      }
  >
}

export type UpdateTagFollowMutationVariables = {
  tag: Scalars['String']
}

export type UpdateTagFollowMutation = { __typename?: 'Mutation' } & {
  updateTagFollow: Maybe<{ __typename?: 'User' } & MePartsFragment>
}

export type UpdateUserFollowMutationVariables = {
  userId: Scalars['String']
}

export type UpdateUserFollowMutation = { __typename?: 'Mutation' } & {
  updateUserFollow: Maybe<{ __typename?: 'User' } & MePartsFragment>
}

export type UpdateUserInfoMutationVariables = {
  updateUserInput: UserInfoInput
}

export type UpdateUserInfoMutation = { __typename?: 'Mutation' } & {
  updateUserInfo: Maybe<
    { __typename?: 'UserInfoResponse' } & Pick<UserInfoResponse, 'message'>
  >
}

export type UpdateUserCodigoMutationVariables = {
  codigo: Scalars['String']
}

export type UpdateUserCodigoMutation = { __typename?: 'Mutation' } & {
  updateUserCodigo: Maybe<
    { __typename?: 'UserInfoResponse' } & Pick<UserInfoResponse, 'message'>
  >
}

export type UserQueryVariables = {
  userId: Scalars['ID']
}

export type UserQuery = { __typename?: 'Query' } & {
  user: Maybe<
    { __typename: 'User' } & Pick<
      User,
      | 'name'
      | 'points'
      | 'id'
      | 'email'
      | 'img'
      | 'officeLocation'
      | 'role'
      | 'department'
      | 'employeeId'
      | 'followersIds'
      | 'usersFollowingIds'
      | 'codigo'
    > & {
        followers: Maybe<
          Array<Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>>
        >
        usersFollowing: Maybe<
          Array<Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>>
        >
      }
  >
}

export type FeaturedTagsQueryVariables = {}

export type FeaturedTagsQuery = { __typename?: 'Query' } & {
  featuredTags: Array<
    Maybe<{ __typename?: 'FeaturedTag' } & Pick<FeaturedTag, 'name' | 'count'>>
  >
}

export type ReplicableIdeasQueryVariables = {}

export type ReplicableIdeasQuery = { __typename?: 'Query' } & {
  replicableIdeas: Array<
    Maybe<
      { __typename?: 'ReplicableTag' } & Pick<ReplicableTag, 'type' | 'count'>
    >
  >
}

export type UpdateNotificationTokenMutationVariables = {
  token?: Maybe<Scalars['String']>
  reset?: Maybe<Scalars['Boolean']>
}

export type UpdateNotificationTokenMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'notificationToken'>

export type MarkNotifsAsReadMutationVariables = {}

export type MarkNotifsAsReadMutation = { __typename?: 'Mutation' } & {
  markAllAsRead: Maybe<{ __typename?: 'User' } & MePartsFragment>
}

export type FeaturedIdeasQueryVariables = {}

export type FeaturedIdeasQuery = { __typename?: 'Query' } & {
  featuredIdeas: Array<
    Maybe<
      { __typename?: 'Idea' } & Pick<
        Idea,
        'name' | 'id' | 'cover' | 'currentUserReaction'
      > &
        IdeaPartsFragment
    >
  >
}

export type NewChallengeMutationVariables = {
  challenge: ChallengeInput
}

export type NewChallengeMutation = { __typename?: 'Mutation' } & {
  newChallenge: Maybe<{ __typename?: 'Challenge' } & Pick<Challenge, 'name'>>
}

export type UpdateChallengeMutationVariables = {
  challengeId: Scalars['ID']
  data: UpdateChallengeInput
}

export type UpdateChallengeMutation = { __typename?: 'Mutation' } & {
  updateChallenge: Maybe<
    { __typename?: 'UpdateChallengeResponse' } & Pick<
      UpdateChallengeResponse,
      'message' | 'id'
    >
  >
}

export type DeleteChallengeMutationVariables = {
  challengeId: Scalars['ID']
}

export type DeleteChallengeMutation = { __typename?: 'Mutation' } & {
  deleteChallenge: Maybe<
    { __typename?: 'DeleteChallengeResponse' } & Pick<
      DeleteChallengeResponse,
      'message' | 'id'
    >
  >
}

export type ChallengesQueryVariables = {}

export type ChallengesQuery = { __typename?: 'Query' } & {
  challenges: Maybe<
    Array<
      Maybe<
        { __typename?: 'Challenge' } & Pick<
          Challenge,
          | 'name'
          | 'id'
          | 'cover'
          | 'enablePending'
          | 'collaboration'
          | 'tags'
          | 'description'
          | 'shortDescription'
        > & {
            ideaQuestions: Array<
              Maybe<
                { __typename?: 'IdeaQuestion' } & Pick<
                  IdeaQuestion,
                  'type' | 'label'
                >
              >
            >
            additionalQuestions: Maybe<
              Array<
                Maybe<
                  { __typename?: 'AdditionalQuestion' } & Pick<
                    AdditionalQuestion,
                    'text' | 'id' | 'selectList' | 'type'
                  >
                >
              >
            >
            questions: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ChallengeRatingQuestion' } & Pick<
                    ChallengeRatingQuestion,
                    'id' | 'title'
                  >
                >
              >
            >
            linkedIdeas: Maybe<
              Array<Maybe<{ __typename?: 'Idea' } & Pick<Idea, 'name' | 'id'>>>
            >
            judges: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Judge' } & Pick<Judge, 'id' | 'name' | 'img'>
                >
              >
            >
            period: Maybe<
              { __typename?: 'ChallengePeriod' } & Pick<
                ChallengePeriod,
                'startDate' | 'dueDate'
              >
            >
          }
      >
    >
  >
}

export type IdeaChallengeRatingsQueryVariables = {
  ideaId: Scalars['ID']
  challengeId: Scalars['ID']
}

export type IdeaChallengeRatingsQuery = { __typename?: 'Query' } & {
  ideaChallengeRatings: Maybe<
    Array<
      Maybe<
        { __typename: 'UserIdeaRating' } & Pick<
          UserIdeaRating,
          'id' | 'ratedAt' | 'average'
        > & {
            ratings: Array<
              Maybe<
                { __typename?: 'UserRating' } & Pick<
                  UserRating,
                  'questionId' | 'rating'
                >
              >
            >
            sender: { __typename: 'User' } & Pick<User, 'id' | 'img' | 'name'>
          }
      >
    >
  >
}

export type StatsQueryVariables = {}

export type StatsQuery = { __typename?: 'Query' } & {
  stats: Maybe<
    { __typename?: 'Stats' } & {
      users: Maybe<
        Array<
          Maybe<
            { __typename?: 'UserStat' } & Pick<UserStat, 'totalUsers' | 'date'>
          >
        >
      >
      ideas: Maybe<
        Array<
          Maybe<
            { __typename?: 'IdeasStat' } & Pick<
              IdeasStat,
              'totalIdeas' | 'date'
            >
          >
        >
      >
      leaderboard: Maybe<
        Array<
          Maybe<
            { __typename?: 'LeadingUserStat' } & Pick<
              LeadingUserStat,
              'username' | 'points' | 'id' | 'image'
            >
          >
        >
      >
      types: Maybe<
        Array<
          Maybe<{ __typename?: 'TypeStat' } & Pick<TypeStat, 'type' | 'ideas'>>
        >
      >
    }
  >
}

export type ChallengeEvolutionQueryVariables = {
  challengeId: Scalars['ID']
}

export type ChallengeEvolutionQuery = { __typename?: 'Query' } & {
  challenge: Maybe<
    { __typename?: 'Challenge' } & Pick<
      Challenge,
      'name' | 'shortDescription' | 'id' | 'cover' | 'tags'
    > & {
        creator: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>
        challengeEvolution: Maybe<
          Array<
            Maybe<
              { __typename?: 'ChallengeEvolution' } & Pick<
                ChallengeEvolution,
                'id' | 'text' | 'color'
              >
            >
          >
        >
      }
  >
}

export type ChallengeQueryVariables = {
  challengeId: Scalars['ID']
}

export type ChallengeQuery = { __typename?: 'Query' } & {
  challenge: Maybe<
    { __typename?: 'Challenge' } & Pick<
      Challenge,
      | 'name'
      | 'shortDescription'
      | 'description'
      | 'id'
      | 'cover'
      | 'creationDate'
      | 'tags'
      | 'status'
      | 'collaboration'
      | 'enablePending'
    > & {
        ideaQuestions: Array<
          Maybe<{ __typename?: 'IdeaQuestion' } & Pick<IdeaQuestion, 'label'>>
        >
        additionalQuestions: Maybe<
          Array<
            Maybe<
              { __typename?: 'AdditionalQuestion' } & Pick<
                AdditionalQuestion,
                'text' | 'type' | 'selectList'
              >
            >
          >
        >
        challengeEvolution: Maybe<
          Array<
            Maybe<
              { __typename?: 'ChallengeEvolution' } & Pick<
                ChallengeEvolution,
                'id' | 'color' | 'text'
              >
            >
          >
        >
        questions: Maybe<
          Array<
            Maybe<
              { __typename?: 'ChallengeRatingQuestion' } & Pick<
                ChallengeRatingQuestion,
                'title' | 'description' | 'scoreType' | 'maxRating'
              > & {
                  rubrics: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Rubric' } & Pick<
                          Rubric,
                          'title' | 'description' | 'color'
                        > & {
                            range: Maybe<
                              { __typename?: 'Range' } & Pick<
                                Range,
                                'min' | 'max'
                              >
                            >
                          }
                      >
                    >
                  >
                }
            >
          >
        >
        judges: Maybe<
          Array<
            Maybe<{ __typename?: 'Judge' } & Pick<Judge, 'id' | 'name' | 'img'>>
          >
        >
        period: Maybe<
          { __typename?: 'ChallengePeriod' } & Pick<
            ChallengePeriod,
            'startDate' | 'dueDate'
          >
        >
        attachments: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attachment' } & Pick<
                Attachment,
                'name' | 'type' | 'url'
              >
            >
          >
        >
        creator: Maybe<
          { __typename?: 'User' } & Pick<User, 'img' | 'name' | 'id'>
        >
        pendingIdeas: Maybe<
          Array<
            Maybe<
              { __typename?: 'Idea' } & Pick<
                Idea,
                'id' | 'name' | 'shortDescription' | 'description' | 'cover'
              > & {
                  creator: Maybe<
                    { __typename?: 'User' } & Pick<User, 'id' | 'name' | 'img'>
                  >
                }
            >
          >
        >
      }
  >
}

export type UpdateLinkedIdeaStageMutationVariables = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
  newStage?: Maybe<Scalars['Int']>
}

export type UpdateLinkedIdeaStageMutation = { __typename?: 'Mutation' } & {
  updateLinkedIdeaStage: Maybe<
    { __typename?: 'UpdateLinkedIdeaStageResponse' } & Pick<
      UpdateLinkedIdeaStageResponse,
      'message' | 'id'
    >
  >
}

export type LinkIdeaMutationVariables = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
  additionalQuestions?: Maybe<AdditionalQuestionAnswers>
}

export type LinkIdeaMutation = { __typename?: 'Mutation' } & {
  linkIdeaToChallenge: Maybe<
    { __typename?: 'Challenge' } & Pick<Challenge, 'name'> & {
        linkedIdeas: Maybe<
          Array<Maybe<{ __typename?: 'Idea' } & Pick<Idea, 'name' | 'id'>>>
        >
      }
  >
}

export type UnlinkIdeaMutationVariables = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type UnlinkIdeaMutation = { __typename?: 'Mutation' } & {
  unlinkIdeaFromChallenge: Maybe<
    { __typename?: 'UnlinkIdeaFromChallengeResponse' } & Pick<
      UnlinkIdeaFromChallengeResponse,
      'message' | 'id'
    >
  >
}

export type ProposeIdeaMutationVariables = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type ProposeIdeaMutation = { __typename?: 'Mutation' } & {
  proposeIdeaToChallenge: Maybe<
    { __typename?: 'Challenge' } & Pick<Challenge, 'name'> & {
        pendingIdeas: Maybe<
          Array<Maybe<{ __typename?: 'Idea' } & Pick<Idea, 'name' | 'id'>>>
        >
      }
  >
}

export type RejectIdeaMutationVariables = {
  challengeId: Scalars['ID']
  ideaId: Scalars['ID']
}

export type RejectIdeaMutation = { __typename?: 'Mutation' } & {
  rejectIdeaFromChallenge: Maybe<
    { __typename?: 'RejectIdeaFromChallengeResponse' } & Pick<
      RejectIdeaFromChallengeResponse,
      'message' | 'id'
    >
  >
}

export type RateIdeaMutationVariables = {
  challengeId?: Maybe<Scalars['ID']>
  ideaId: Scalars['ID']
  ratings: Array<Maybe<UserIdeaRatingInput>>
}

export type RateIdeaMutation = { __typename?: 'Mutation' } & {
  rateIdeaForChallenge: Maybe<
    { __typename?: 'Idea' } & {
      challenge: Maybe<
        { __typename?: 'IdeaChallenge' } & Pick<
          IdeaChallenge,
          'name' | 'ratingType' | 'ratingAverage'
        > & {
            period: Maybe<
              { __typename?: 'ChallengePeriod' } & Pick<
                ChallengePeriod,
                'startDate' | 'dueDate'
              >
            >
            ratings: Maybe<
              Array<
                Maybe<
                  { __typename?: 'IdeaRating' } & Pick<
                    IdeaRating,
                    'title' | 'id' | 'ratingAverage'
                  >
                >
              >
            >
            currentUserRating: Maybe<
              { __typename?: 'UserIdeaRating' } & {
                ratings: Array<
                  Maybe<
                    { __typename?: 'UserRating' } & Pick<
                      UserRating,
                      'rating' | 'questionId'
                    >
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type PointsQueryVariables = {}

export type PointsQuery = { __typename?: 'Query' } & {
  points: Maybe<
    { __typename?: 'Points' } & Pick<
      Points,
      | 'comment'
      | 'ideaCreation'
      | 'ideaEvolution'
      | 'reaction'
      | 'respondToChallenge'
      | 'ideaFavorite'
      | 'collaboration'
      | 'filtroRevision'
      | 'ideaGanadora'
    >
  >
}

export type SetPointsMutationVariables = {
  points: PointsInput
}

export type SetPointsMutation = { __typename?: 'Mutation' } & {
  points: Maybe<
    { __typename?: 'Points' } & Pick<
      Points,
      | 'comment'
      | 'ideaCreation'
      | 'ideaEvolution'
      | 'reaction'
      | 'respondToChallenge'
      | 'ideaFavorite'
      | 'collaboration'
      | 'filtroRevision'
      | 'ideaGanadora'
    >
  >
}

export type AppSettingsQueryVariables = {}

export type AppSettingsQuery = { __typename?: 'Query' } & {
  appSettings: Maybe<
    { __typename?: 'AppSettings' } & Pick<AppSettings, 'featuredIdeas'> & {
        points: Maybe<
          { __typename?: 'Points' } & Pick<
            Points,
            | 'comment'
            | 'ideaCreation'
            | 'ideaEvolution'
            | 'reaction'
            | 'respondToChallenge'
            | 'ideaFavorite'
            | 'collaboration'
            | 'filtroRevision'
            | 'ideaGanadora'
          >
        >
        ideaTypes: Maybe<
          Array<
            Maybe<{ __typename?: 'IdeaType' } & Pick<IdeaType, 'id' | 'name'>>
          >
        >
      }
  >
}

export type IdeaTypesQueryVariables = {}

export type IdeaTypesQuery = { __typename?: 'Query' } & {
  appSettings: Maybe<
    { __typename?: 'AppSettings' } & {
      ideaTypes: Maybe<
        Array<
          Maybe<{ __typename?: 'IdeaType' } & Pick<IdeaType, 'id' | 'name'>>
        >
      >
    }
  >
}

export type SetAdminSettingsMutationVariables = {
  settings: SettingsInput
}

export type SetAdminSettingsMutation = { __typename?: 'Mutation' } & {
  settings: Maybe<
    { __typename?: 'AppSettings' } & Pick<AppSettings, 'featuredIdeas'> & {
        points: Maybe<
          { __typename?: 'Points' } & Pick<
            Points,
            | 'comment'
            | 'ideaCreation'
            | 'ideaEvolution'
            | 'reaction'
            | 'respondToChallenge'
            | 'ideaFavorite'
            | 'collaboration'
            | 'filtroRevision'
            | 'ideaGanadora'
          >
        >
        ideaTypes: Maybe<
          Array<
            Maybe<{ __typename?: 'IdeaType' } & Pick<IdeaType, 'id' | 'name'>>
          >
        >
      }
  >
}

import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as ReactApolloHooks from 'react-apollo-hooks'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export const IdeaPartsFragmentDoc = gql`
  fragment IdeaParts on Idea {
    __typename
    name
    shortDescription
    description
    id
    creator {
      id
      name
      img
      email
      __typename @skip(if: true)
    }
    cover
    type
    creationDate
    tags
    stage
    favorite
    hasAttachment
    attachments {
      name
      type
      url
    }
    reactionCount {
      love
      like
      dislike
      __typename
    }
  }
`
export const MePartsFragmentDoc = gql`
  fragment MeParts on User {
    name
    id
    img
    uid
    isAdmin
    officeLocation
    role
    department
    employeeId
    points
    email
    favoriteTags
    usersFollowingIds
    usersFollowing {
      name
      id
    }
    reactedIdeas {
      name
      id
    }
    notifications {
      type
      date
      read
      who {
        name
        id
      }
      target {
        class
        id
        name
      }
    }
    codigo
  }
`
export const CommentPartsFragmentDoc = gql`
  fragment CommentParts on Comment {
    id
    text
    creator {
      id
      name
      img
      __typename @skip(if: true)
    }
    creationDate
    replyCount
    attachments {
      name
      type
      url
    }
    cover
    private
    tag {
      id
      name
    }
  }
`
export const NotePartsFragmentDoc = gql`
  fragment NoteParts on Note {
    id
    text
    creator {
      id
      name
      img
      __typename @skip(if: true)
    }
    creationDate
    replyCount
  }
`
export const StatisticPartsFragmentDoc = gql`
  fragment StatisticParts on Statistic {
    id
    name
    description
    type
    label
    tag
    value {
      x
      y
    }
  }
`
export const ReplyPartsFragmentDoc = gql`
  fragment ReplyParts on Reply {
    id
    replyId
    text
    creator {
      id
      name
      img
    }
    creationDate
    isReply
    attachments {
      name
      type
      url
    }
    cover
    private
    replyTo {
      id
      name
    }
    tag {
      id
      name
    }
  }
`
export const ReplyNotePartsFragmentDoc = gql`
  fragment ReplyNoteParts on ReplyNote {
    id
    replyId
    text
    creator {
      id
      name
      img
    }
    creationDate
    isReply
    replyTo {
      id
      name
    }
  }
`
export const AutocompleteDocument = gql`
  query Autocomplete {
    autocomplete
  }
`

export const AutocompleteComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<AutocompleteQuery, AutocompleteQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: AutocompleteQueryVariables }
) => (
  <ReactApollo.Query<AutocompleteQuery, AutocompleteQueryVariables>
    query={AutocompleteDocument}
    {...props}
  />
)

export function useAutocompleteQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<AutocompleteQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    AutocompleteQuery,
    AutocompleteQueryVariables
  >(AutocompleteDocument, baseOptions)
}
export const NewIdeaDocument = gql`
  mutation NewIdea($idea: IdeaInput!) {
    newIdea(idea: $idea) {
      id
      name
      shortDescription
      cover
      description
      tags
      type
      attachments {
        name
        type
        url
      }
    }
  }
`
export type NewIdeaMutationFn = ReactApollo.MutationFn<
  NewIdeaMutation,
  NewIdeaMutationVariables
>

export const NewIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<NewIdeaMutation, NewIdeaMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: NewIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<NewIdeaMutation, NewIdeaMutationVariables>
    mutation={NewIdeaDocument}
    {...props}
  />
)

export function useNewIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewIdeaMutation,
    NewIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewIdeaMutation,
    NewIdeaMutationVariables
  >(NewIdeaDocument, baseOptions)
}
export const DeleteIdeaDocument = gql`
  mutation DeleteIdea($ideaId: ID!) {
    deleteIdea(ideaId: $ideaId) {
      message
      id
    }
  }
`
export type DeleteIdeaMutationFn = ReactApollo.MutationFn<
  DeleteIdeaMutation,
  DeleteIdeaMutationVariables
>

export const DeleteIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        DeleteIdeaMutation,
        DeleteIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: DeleteIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<DeleteIdeaMutation, DeleteIdeaMutationVariables>
    mutation={DeleteIdeaDocument}
    {...props}
  />
)

export function useDeleteIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteIdeaMutation,
    DeleteIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteIdeaMutation,
    DeleteIdeaMutationVariables
  >(DeleteIdeaDocument, baseOptions)
}
export const NewUserDocument = gql`
  mutation NewUser($user: UserInput!) {
    newUser(user: $user) {
      img
    }
  }
`
export type NewUserMutationFn = ReactApollo.MutationFn<
  NewUserMutation,
  NewUserMutationVariables
>

export const NewUserComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<NewUserMutation, NewUserMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: NewUserMutationVariables }
) => (
  <ReactApollo.Mutation<NewUserMutation, NewUserMutationVariables>
    mutation={NewUserDocument}
    {...props}
  />
)

export function useNewUserMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewUserMutation,
    NewUserMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewUserMutation,
    NewUserMutationVariables
  >(NewUserDocument, baseOptions)
}
export const IdeasDocument = gql`
  query Ideas(
    $filter: IdeaFilters
    $cursor: String
    $size: Int
    $value: String
    $replicable: Boolean
  ) {
    ideas(
      filter: $filter
      cursor: $cursor
      size: $size
      value: $value
      replicable: $replicable
    ) {
      ideas {
        ...IdeaParts
        currentUserReaction
        challenge {
          name
          numberOfVotes
          id
          ratingType
          ratingAverage
          cover
          period {
            startDate
            dueDate
          }
          stage
          additionalQuestions {
            text
            answer
          }
          ratings {
            title
            sum
            id
            maxRating
            ratingAverage
            __typename @skip(if: true)
          }
          currentUserRating {
            ratings {
              rating
              questionId
              __typename @skip(if: true)
            }
          }
          __typename @skip(if: true)
        }
        vectorFields {
          scores {
            RPA
            BI
            RPApercentage
            BIpercentage
          }
        }
      }
      hasNextPage
    }
  }
  ${IdeaPartsFragmentDoc}
`

export const IdeasComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<IdeasQuery, IdeasQueryVariables>, 'query'>,
    'variables'
  > & { variables?: IdeasQueryVariables }
) => (
  <ReactApollo.Query<IdeasQuery, IdeasQueryVariables>
    query={IdeasDocument}
    {...props}
  />
)

export function useIdeasQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeasQueryVariables>
) {
  return ReactApolloHooks.useQuery<IdeasQuery, IdeasQueryVariables>(
    IdeasDocument,
    baseOptions
  )
}
export const IdeasDestacadasDocument = gql`
  query IdeasDestacadas {
    ideasDestacadas {
      ...IdeaParts
    }
  }
  ${IdeaPartsFragmentDoc}
`

export const IdeasDestacadasComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        IdeasDestacadasQuery,
        IdeasDestacadasQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: IdeasDestacadasQueryVariables }
) => (
  <ReactApollo.Query<IdeasDestacadasQuery, IdeasDestacadasQueryVariables>
    query={IdeasDestacadasDocument}
    {...props}
  />
)

export function useIdeasDestacadasQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeasDestacadasQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IdeasDestacadasQuery,
    IdeasDestacadasQueryVariables
  >(IdeasDestacadasDocument, baseOptions)
}
export const IdeaDocument = gql`
  query Idea($ideaId: ID!) {
    idea(ideaId: $ideaId) {
      ...IdeaParts
      commentCount
      noteCount
      currentUserReaction
      softtekFields {
        Categorias {
          Categoria
          Valor
          Competencias
          __typename @skip(if: true)
        }
      }
      softtekAutoFields {
        market
        industry
        account
        service
        platform
        replicable
        repositoryCode
        responsible
        licenseRequired
        propietary
        automationType
        complexity
        __typename @skip(if: true)
      }
      abancaFields {
        periodicidad
        tiempoDedicado
        volumenEstimado
        tiempoTarea
        tiempoTareaVolumen
        riesgoOperacion
        ahorroCostes
        fechaMax
        peticionCER
        departmentTitle
        departmentValue
        departmentLevels
        collaborators {
          id
          name
          img
        }
      }
      vectorFields {
        responsable
        departamento
        area
        manual
        frecuencia
        volumetrias
        volumetriasUM
        duracion
        duracionUM
        picosOperativos
        informacionPicos
        aspectos
        consistencia
        reglas
        datosEstandarizados
        datosLegibles
        procedimientoHabitual
        cambiosProceso
        cambioSistema
        NoAplicaciones
        scores {
          RPA
          BI
          RPApercentage
          BIpercentage
        }
      }
      dowFields {
        LabTechnician
        InitialDate
        FinalDate
        MonthAndYear
        ActivityType
        ProjectId
        ProjectActivity
        DedicatedTime
        TotalWorkedHours
        DedicateTime
        ProjectTitle
        BusinessSegment
        BusinessPriority
        TechComplex
        Status
        ClosureDate
        TypeProject
        LabProjectLeader
        TSYD
        CRM
        NPI
        DelayReasons
        GeneralComments
        Code
        RegistrationDate
        EstimatedProjectDuration
        TotalProjectDuration
        __typename @skip(if: true)
      }
      hebFields {
        statusIdea
      }
      adminTags
      flagged {
        flagged
        reason
      }
      challenge {
        name
        id
        ratingType
        status
        ratingAverage
        cover
        period {
          startDate
          dueDate
        }
        ratings {
          title
          description
          scoreType
          maxRating
          id
          ratingAverage
          sum
          rubrics {
            title
            description
            color
            range {
              min
              max
            }
          }
          __typename @skip(if: true)
        }
        currentUserRating {
          ratings {
            rating
            questionId
            __typename @skip(if: true)
          }
        }
        enablePending
        collaboration
        judges {
          id
          name
          img
        }
        __typename @skip(if: true)
      }
    }
  }
  ${IdeaPartsFragmentDoc}
`

export const IdeaComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<IdeaQuery, IdeaQueryVariables>, 'query'>,
    'variables'
  > & { variables: IdeaQueryVariables }
) => (
  <ReactApollo.Query<IdeaQuery, IdeaQueryVariables>
    query={IdeaDocument}
    {...props}
  />
)

export function useIdeaQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaQueryVariables>
) {
  return ReactApolloHooks.useQuery<IdeaQuery, IdeaQueryVariables>(
    IdeaDocument,
    baseOptions
  )
}
export const IdeaReactionsDocument = gql`
  query IdeaReactions($ideaId: ID!) {
    ideaReactions(ideaId: $ideaId) {
      reaction
      updatedAt
      user {
        id
        name
      }
    }
  }
`

export const IdeaReactionsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<IdeaReactionsQuery, IdeaReactionsQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: IdeaReactionsQueryVariables }
) => (
  <ReactApollo.Query<IdeaReactionsQuery, IdeaReactionsQueryVariables>
    query={IdeaReactionsDocument}
    {...props}
  />
)

export function useIdeaReactionsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaReactionsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IdeaReactionsQuery,
    IdeaReactionsQueryVariables
  >(IdeaReactionsDocument, baseOptions)
}
export const UpdateIdeaReactionDocument = gql`
  mutation UpdateIdeaReaction($ideaId: ID!, $reaction: String!) {
    updateReaction(ideaId: $ideaId, reaction: $reaction) {
      ...IdeaParts
      currentUserReaction
    }
  }
  ${IdeaPartsFragmentDoc}
`
export type UpdateIdeaReactionMutationFn = ReactApollo.MutationFn<
  UpdateIdeaReactionMutation,
  UpdateIdeaReactionMutationVariables
>

export const UpdateIdeaReactionComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateIdeaReactionMutation,
        UpdateIdeaReactionMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateIdeaReactionMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateIdeaReactionMutation,
    UpdateIdeaReactionMutationVariables
  >
    mutation={UpdateIdeaReactionDocument}
    {...props}
  />
)

export function useUpdateIdeaReactionMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateIdeaReactionMutation,
    UpdateIdeaReactionMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateIdeaReactionMutation,
    UpdateIdeaReactionMutationVariables
  >(UpdateIdeaReactionDocument, baseOptions)
}
export const FlagIdeaDocument = gql`
  mutation FlagIdea($ideaId: ID!, $flagInput: FlagInput!) {
    flagIdea(ideaId: $ideaId, flagInput: $flagInput) {
      message
      id
    }
  }
`
export type FlagIdeaMutationFn = ReactApollo.MutationFn<
  FlagIdeaMutation,
  FlagIdeaMutationVariables
>

export const FlagIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<FlagIdeaMutation, FlagIdeaMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: FlagIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<FlagIdeaMutation, FlagIdeaMutationVariables>
    mutation={FlagIdeaDocument}
    {...props}
  />
)

export function useFlagIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    FlagIdeaMutation,
    FlagIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    FlagIdeaMutation,
    FlagIdeaMutationVariables
  >(FlagIdeaDocument, baseOptions)
}
export const SasDocument = gql`
  query Sas {
    sas {
      token
      expiresBy
    }
  }
`

export const SasComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<SasQuery, SasQueryVariables>, 'query'>,
    'variables'
  > & { variables?: SasQueryVariables }
) => (
  <ReactApollo.Query<SasQuery, SasQueryVariables>
    query={SasDocument}
    {...props}
  />
)

export function useSasQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<SasQueryVariables>
) {
  return ReactApolloHooks.useQuery<SasQuery, SasQueryVariables>(
    SasDocument,
    baseOptions
  )
}
export const MeDocument = gql`
  query Me {
    me {
      ...MeParts
      sas {
        token
        expiresBy
      }
    }
  }
  ${MePartsFragmentDoc}
`

export const MeComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<MeQuery, MeQueryVariables>, 'query'>,
    'variables'
  > & { variables?: MeQueryVariables }
) => (
  <ReactApollo.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
)

export function useMeQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<MeQueryVariables>
) {
  return ReactApolloHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  )
}
export const UpdateIdeaDocument = gql`
  mutation UpdateIdea($ideaId: ID!, $data: UpdateIdeaInput!) {
    updateIdea(ideaId: $ideaId, data: $data) {
      name
      description
      id
    }
  }
`
export type UpdateIdeaMutationFn = ReactApollo.MutationFn<
  UpdateIdeaMutation,
  UpdateIdeaMutationVariables
>

export const UpdateIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateIdeaMutation,
        UpdateIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<UpdateIdeaMutation, UpdateIdeaMutationVariables>
    mutation={UpdateIdeaDocument}
    {...props}
  />
)

export function useUpdateIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateIdeaMutation,
    UpdateIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateIdeaMutation,
    UpdateIdeaMutationVariables
  >(UpdateIdeaDocument, baseOptions)
}
export const UpdateTagGitHubDocument = gql`
  mutation UpdateTagGitHub($ideaId: ID!) {
    updateTagGitHub(ideaId: $ideaId) {
      tags
    }
  }
`
export type UpdateTagGitHubMutationFn = ReactApollo.MutationFn<
  UpdateTagGitHubMutation,
  UpdateTagGitHubMutationVariables
>

export const UpdateTagGitHubComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateTagGitHubMutation,
        UpdateTagGitHubMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateTagGitHubMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateTagGitHubMutation,
    UpdateTagGitHubMutationVariables
  >
    mutation={UpdateTagGitHubDocument}
    {...props}
  />
)

export function useUpdateTagGitHubMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateTagGitHubMutation,
    UpdateTagGitHubMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateTagGitHubMutation,
    UpdateTagGitHubMutationVariables
  >(UpdateTagGitHubDocument, baseOptions)
}
export const NewCommentDocument = gql`
  mutation NewComment($ideaId: ID!, $comment: CommentInput!) {
    newComment(ideaId: $ideaId, comment: $comment) {
      ...CommentParts
    }
  }
  ${CommentPartsFragmentDoc}
`
export type NewCommentMutationFn = ReactApollo.MutationFn<
  NewCommentMutation,
  NewCommentMutationVariables
>

export const NewCommentComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        NewCommentMutation,
        NewCommentMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: NewCommentMutationVariables }
) => (
  <ReactApollo.Mutation<NewCommentMutation, NewCommentMutationVariables>
    mutation={NewCommentDocument}
    {...props}
  />
)

export function useNewCommentMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewCommentMutation,
    NewCommentMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewCommentMutation,
    NewCommentMutationVariables
  >(NewCommentDocument, baseOptions)
}
export const NewNoteDocument = gql`
  mutation NewNote($ideaId: ID!, $note: NoteInput!) {
    newNote(ideaId: $ideaId, note: $note) {
      ...NoteParts
    }
  }
  ${NotePartsFragmentDoc}
`
export type NewNoteMutationFn = ReactApollo.MutationFn<
  NewNoteMutation,
  NewNoteMutationVariables
>

export const NewNoteComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<NewNoteMutation, NewNoteMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: NewNoteMutationVariables }
) => (
  <ReactApollo.Mutation<NewNoteMutation, NewNoteMutationVariables>
    mutation={NewNoteDocument}
    {...props}
  />
)

export function useNewNoteMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewNoteMutation,
    NewNoteMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewNoteMutation,
    NewNoteMutationVariables
  >(NewNoteDocument, baseOptions)
}
export const DeleteCommentDocument = gql`
  mutation DeleteComment($ideaId: ID!, $commentId: ID!, $commentUser: ID!) {
    deleteComment(
      ideaId: $ideaId
      commentId: $commentId
      commentUser: $commentUser
    ) {
      message
      id
    }
  }
`
export type DeleteCommentMutationFn = ReactApollo.MutationFn<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>

export const DeleteCommentComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        DeleteCommentMutation,
        DeleteCommentMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: DeleteCommentMutationVariables }
) => (
  <ReactApollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables>
    mutation={DeleteCommentDocument}
    {...props}
  />
)

export function useDeleteCommentMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, baseOptions)
}
export const DeleteNoteDocument = gql`
  mutation DeleteNote($ideaId: ID!, $noteId: ID!, $noteUser: ID!) {
    deleteNote(ideaId: $ideaId, noteId: $noteId, noteUser: $noteUser) {
      message
      id
    }
  }
`
export type DeleteNoteMutationFn = ReactApollo.MutationFn<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>

export const DeleteNoteComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        DeleteNoteMutation,
        DeleteNoteMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: DeleteNoteMutationVariables }
) => (
  <ReactApollo.Mutation<DeleteNoteMutation, DeleteNoteMutationVariables>
    mutation={DeleteNoteDocument}
    {...props}
  />
)

export function useDeleteNoteMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >(DeleteNoteDocument, baseOptions)
}
export const UpdateFavoriteIdeaDocument = gql`
  mutation UpdateFavoriteIdea($ideaId: ID!, $favorite: Boolean!) {
    updateFavoriteIdea(ideaId: $ideaId, favorite: $favorite) {
      message
      id
    }
  }
`
export type UpdateFavoriteIdeaMutationFn = ReactApollo.MutationFn<
  UpdateFavoriteIdeaMutation,
  UpdateFavoriteIdeaMutationVariables
>

export const UpdateFavoriteIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateFavoriteIdeaMutation,
        UpdateFavoriteIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateFavoriteIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateFavoriteIdeaMutation,
    UpdateFavoriteIdeaMutationVariables
  >
    mutation={UpdateFavoriteIdeaDocument}
    {...props}
  />
)

export function useUpdateFavoriteIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateFavoriteIdeaMutation,
    UpdateFavoriteIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateFavoriteIdeaMutation,
    UpdateFavoriteIdeaMutationVariables
  >(UpdateFavoriteIdeaDocument, baseOptions)
}
export const UpdateIdeaStageDocument = gql`
  mutation UpdateIdeaStage($ideaId: ID!, $newStage: Int!) {
    updateIdeaStage(ideaId: $ideaId, newStage: $newStage) {
      message
      id
    }
  }
`
export type UpdateIdeaStageMutationFn = ReactApollo.MutationFn<
  UpdateIdeaStageMutation,
  UpdateIdeaStageMutationVariables
>

export const UpdateIdeaStageComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateIdeaStageMutation,
        UpdateIdeaStageMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateIdeaStageMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateIdeaStageMutation,
    UpdateIdeaStageMutationVariables
  >
    mutation={UpdateIdeaStageDocument}
    {...props}
  />
)

export function useUpdateIdeaStageMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateIdeaStageMutation,
    UpdateIdeaStageMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateIdeaStageMutation,
    UpdateIdeaStageMutationVariables
  >(UpdateIdeaStageDocument, baseOptions)
}
export const UpdateProfilePicDocument = gql`
  mutation UpdateProfilePic($newImg: String!) {
    updateProfilePic(newImg: $newImg) {
      message
      userId
    }
  }
`
export type UpdateProfilePicMutationFn = ReactApollo.MutationFn<
  UpdateProfilePicMutation,
  UpdateProfilePicMutationVariables
>

export const UpdateProfilePicComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateProfilePicMutation,
        UpdateProfilePicMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateProfilePicMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateProfilePicMutation,
    UpdateProfilePicMutationVariables
  >
    mutation={UpdateProfilePicDocument}
    {...props}
  />
)

export function useUpdateProfilePicMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateProfilePicMutation,
    UpdateProfilePicMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateProfilePicMutation,
    UpdateProfilePicMutationVariables
  >(UpdateProfilePicDocument, baseOptions)
}
export const NewReplyDocument = gql`
  mutation NewReply($ideaId: ID!, $reply: ReplyInput!) {
    newReply(ideaId: $ideaId, reply: $reply) {
      id
      replyId
      text
      creator {
        id
        name
      }
      creationDate
      isReply
      replyTo {
        id
        name
      }
      tag {
        id
        name
      }
    }
  }
`
export type NewReplyMutationFn = ReactApollo.MutationFn<
  NewReplyMutation,
  NewReplyMutationVariables
>

export const NewReplyComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<NewReplyMutation, NewReplyMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: NewReplyMutationVariables }
) => (
  <ReactApollo.Mutation<NewReplyMutation, NewReplyMutationVariables>
    mutation={NewReplyDocument}
    {...props}
  />
)

export function useNewReplyMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewReplyMutation,
    NewReplyMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewReplyMutation,
    NewReplyMutationVariables
  >(NewReplyDocument, baseOptions)
}
export const NewReplyNoteDocument = gql`
  mutation newReplyNote($ideaId: ID!, $reply: ReplyInputNote!) {
    newReplyNote(ideaId: $ideaId, reply: $reply) {
      id
      replyId
      text
      creator {
        id
        name
      }
      creationDate
      isReply
      replyTo {
        id
        name
      }
    }
  }
`
export type NewReplyNoteMutationFn = ReactApollo.MutationFn<
  NewReplyNoteMutation,
  NewReplyNoteMutationVariables
>

export const NewReplyNoteComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        NewReplyNoteMutation,
        NewReplyNoteMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: NewReplyNoteMutationVariables }
) => (
  <ReactApollo.Mutation<NewReplyNoteMutation, NewReplyNoteMutationVariables>
    mutation={NewReplyNoteDocument}
    {...props}
  />
)

export function useNewReplyNoteMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewReplyNoteMutation,
    NewReplyNoteMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewReplyNoteMutation,
    NewReplyNoteMutationVariables
  >(NewReplyNoteDocument, baseOptions)
}
export const IdeaCommentsDocument = gql`
  query IdeaComments($ideaId: ID!, $size: Int, $cursor: String) {
    ideaComments(ideaId: $ideaId, size: $size, cursor: $cursor) {
      hasNextPage
      comments {
        ...CommentParts
      }
    }
  }
  ${CommentPartsFragmentDoc}
`

export const IdeaCommentsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<IdeaCommentsQuery, IdeaCommentsQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: IdeaCommentsQueryVariables }
) => (
  <ReactApollo.Query<IdeaCommentsQuery, IdeaCommentsQueryVariables>
    query={IdeaCommentsDocument}
    {...props}
  />
)

export function useIdeaCommentsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaCommentsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IdeaCommentsQuery,
    IdeaCommentsQueryVariables
  >(IdeaCommentsDocument, baseOptions)
}
export const IdeaNotesDocument = gql`
  query IdeaNotes($ideaId: ID!, $size: Int, $cursor: String) {
    ideaNotes(ideaId: $ideaId, size: $size, cursor: $cursor) {
      hasNextPage
      notes {
        ...NoteParts
      }
    }
  }
  ${NotePartsFragmentDoc}
`

export const IdeaNotesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<IdeaNotesQuery, IdeaNotesQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: IdeaNotesQueryVariables }
) => (
  <ReactApollo.Query<IdeaNotesQuery, IdeaNotesQueryVariables>
    query={IdeaNotesDocument}
    {...props}
  />
)

export function useIdeaNotesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaNotesQueryVariables>
) {
  return ReactApolloHooks.useQuery<IdeaNotesQuery, IdeaNotesQueryVariables>(
    IdeaNotesDocument,
    baseOptions
  )
}
export const IdeaStatisticsDocument = gql`
  query IdeaStatistics($ideaId: ID!, $size: Int) {
    ideaStatistics(ideaId: $ideaId, size: $size) {
      ...StatisticParts
    }
  }
  ${StatisticPartsFragmentDoc}
`

export const IdeaStatisticsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<IdeaStatisticsQuery, IdeaStatisticsQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: IdeaStatisticsQueryVariables }
) => (
  <ReactApollo.Query<IdeaStatisticsQuery, IdeaStatisticsQueryVariables>
    query={IdeaStatisticsDocument}
    {...props}
  />
)

export function useIdeaStatisticsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaStatisticsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IdeaStatisticsQuery,
    IdeaStatisticsQueryVariables
  >(IdeaStatisticsDocument, baseOptions)
}
export const CommentRepliesDocument = gql`
  query CommentReplies(
    $ideaId: ID!
    $commentId: ID!
    $size: Int
    $cursor: String
  ) {
    commentReplies(
      ideaId: $ideaId
      commentId: $commentId
      size: $size
      cursor: $cursor
    ) {
      hasNextPage
      replies {
        ...ReplyParts
      }
    }
  }
  ${ReplyPartsFragmentDoc}
`

export const CommentRepliesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<CommentRepliesQuery, CommentRepliesQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: CommentRepliesQueryVariables }
) => (
  <ReactApollo.Query<CommentRepliesQuery, CommentRepliesQueryVariables>
    query={CommentRepliesDocument}
    {...props}
  />
)

export function useCommentRepliesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<CommentRepliesQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    CommentRepliesQuery,
    CommentRepliesQueryVariables
  >(CommentRepliesDocument, baseOptions)
}
export const NoteRepliesDocument = gql`
  query noteReplies($ideaId: ID!, $noteId: ID!, $size: Int, $cursor: String) {
    noteReplies(
      ideaId: $ideaId
      noteId: $noteId
      size: $size
      cursor: $cursor
    ) {
      hasNextPage
      replies {
        ...ReplyNoteParts
      }
    }
  }
  ${ReplyNotePartsFragmentDoc}
`

export const NoteRepliesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<NoteRepliesQuery, NoteRepliesQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: NoteRepliesQueryVariables }
) => (
  <ReactApollo.Query<NoteRepliesQuery, NoteRepliesQueryVariables>
    query={NoteRepliesDocument}
    {...props}
  />
)

export function useNoteRepliesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<NoteRepliesQueryVariables>
) {
  return ReactApolloHooks.useQuery<NoteRepliesQuery, NoteRepliesQueryVariables>(
    NoteRepliesDocument,
    baseOptions
  )
}
export const UpdateTagFollowDocument = gql`
  mutation UpdateTagFollow($tag: String!) {
    updateTagFollow(tag: $tag) {
      ...MeParts
    }
  }
  ${MePartsFragmentDoc}
`
export type UpdateTagFollowMutationFn = ReactApollo.MutationFn<
  UpdateTagFollowMutation,
  UpdateTagFollowMutationVariables
>

export const UpdateTagFollowComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateTagFollowMutation,
        UpdateTagFollowMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateTagFollowMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateTagFollowMutation,
    UpdateTagFollowMutationVariables
  >
    mutation={UpdateTagFollowDocument}
    {...props}
  />
)

export function useUpdateTagFollowMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateTagFollowMutation,
    UpdateTagFollowMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateTagFollowMutation,
    UpdateTagFollowMutationVariables
  >(UpdateTagFollowDocument, baseOptions)
}
export const UpdateUserFollowDocument = gql`
  mutation UpdateUserFollow($userId: String!) {
    updateUserFollow(userId: $userId) {
      ...MeParts
    }
  }
  ${MePartsFragmentDoc}
`
export type UpdateUserFollowMutationFn = ReactApollo.MutationFn<
  UpdateUserFollowMutation,
  UpdateUserFollowMutationVariables
>

export const UpdateUserFollowComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateUserFollowMutation,
        UpdateUserFollowMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateUserFollowMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateUserFollowMutation,
    UpdateUserFollowMutationVariables
  >
    mutation={UpdateUserFollowDocument}
    {...props}
  />
)

export function useUpdateUserFollowMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateUserFollowMutation,
    UpdateUserFollowMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateUserFollowMutation,
    UpdateUserFollowMutationVariables
  >(UpdateUserFollowDocument, baseOptions)
}
export const UpdateUserInfoDocument = gql`
  mutation UpdateUserInfo($updateUserInput: UserInfoInput!) {
    updateUserInfo(updateUserInput: $updateUserInput) {
      message
    }
  }
`
export type UpdateUserInfoMutationFn = ReactApollo.MutationFn<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>

export const UpdateUserInfoComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateUserInfoMutation,
        UpdateUserInfoMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateUserInfoMutationVariables }
) => (
  <ReactApollo.Mutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>
    mutation={UpdateUserInfoDocument}
    {...props}
  />
)

export function useUpdateUserInfoMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >(UpdateUserInfoDocument, baseOptions)
}
export const UpdateUserCodigoDocument = gql`
  mutation UpdateUserCodigo($codigo: String!) {
    updateUserCodigo(codigo: $codigo) {
      message
    }
  }
`
export type UpdateUserCodigoMutationFn = ReactApollo.MutationFn<
  UpdateUserCodigoMutation,
  UpdateUserCodigoMutationVariables
>

export const UpdateUserCodigoComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateUserCodigoMutation,
        UpdateUserCodigoMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateUserCodigoMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateUserCodigoMutation,
    UpdateUserCodigoMutationVariables
  >
    mutation={UpdateUserCodigoDocument}
    {...props}
  />
)

export function useUpdateUserCodigoMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateUserCodigoMutation,
    UpdateUserCodigoMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateUserCodigoMutation,
    UpdateUserCodigoMutationVariables
  >(UpdateUserCodigoDocument, baseOptions)
}
export const UserDocument = gql`
  query User($userId: ID!) {
    user(userId: $userId) {
      name
      points
      id
      email
      img
      followers {
        id
        name
      }
      officeLocation
      role
      department
      employeeId
      followersIds
      usersFollowingIds
      usersFollowing {
        id
        name
      }
      codigo
      __typename @skip(if: true)
    }
  }
`

export const UserComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<UserQuery, UserQueryVariables>, 'query'>,
    'variables'
  > & { variables: UserQueryVariables }
) => (
  <ReactApollo.Query<UserQuery, UserQueryVariables>
    query={UserDocument}
    {...props}
  />
)

export function useUserQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<UserQueryVariables>
) {
  return ReactApolloHooks.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions
  )
}
export const FeaturedTagsDocument = gql`
  query FeaturedTags {
    featuredTags {
      name
      count
    }
  }
`

export const FeaturedTagsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<FeaturedTagsQuery, FeaturedTagsQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: FeaturedTagsQueryVariables }
) => (
  <ReactApollo.Query<FeaturedTagsQuery, FeaturedTagsQueryVariables>
    query={FeaturedTagsDocument}
    {...props}
  />
)

export function useFeaturedTagsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FeaturedTagsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    FeaturedTagsQuery,
    FeaturedTagsQueryVariables
  >(FeaturedTagsDocument, baseOptions)
}
export const ReplicableIdeasDocument = gql`
  query ReplicableIdeas {
    replicableIdeas {
      type
      count
    }
  }
`

export const ReplicableIdeasComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ReplicableIdeasQuery,
        ReplicableIdeasQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: ReplicableIdeasQueryVariables }
) => (
  <ReactApollo.Query<ReplicableIdeasQuery, ReplicableIdeasQueryVariables>
    query={ReplicableIdeasDocument}
    {...props}
  />
)

export function useReplicableIdeasQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ReplicableIdeasQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    ReplicableIdeasQuery,
    ReplicableIdeasQueryVariables
  >(ReplicableIdeasDocument, baseOptions)
}
export const UpdateNotificationTokenDocument = gql`
  mutation UpdateNotificationToken($token: String, $reset: Boolean) {
    notificationToken(token: $token, reset: $reset)
  }
`
export type UpdateNotificationTokenMutationFn = ReactApollo.MutationFn<
  UpdateNotificationTokenMutation,
  UpdateNotificationTokenMutationVariables
>

export const UpdateNotificationTokenComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateNotificationTokenMutation,
        UpdateNotificationTokenMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateNotificationTokenMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateNotificationTokenMutation,
    UpdateNotificationTokenMutationVariables
  >
    mutation={UpdateNotificationTokenDocument}
    {...props}
  />
)

export function useUpdateNotificationTokenMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateNotificationTokenMutation,
    UpdateNotificationTokenMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateNotificationTokenMutation,
    UpdateNotificationTokenMutationVariables
  >(UpdateNotificationTokenDocument, baseOptions)
}
export const MarkNotifsAsReadDocument = gql`
  mutation MarkNotifsASRead {
    markAllAsRead {
      ...MeParts
    }
  }
  ${MePartsFragmentDoc}
`
export type MarkNotifsAsReadMutationFn = ReactApollo.MutationFn<
  MarkNotifsAsReadMutation,
  MarkNotifsAsReadMutationVariables
>

export const MarkNotifsAsReadComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        MarkNotifsAsReadMutation,
        MarkNotifsAsReadMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: MarkNotifsAsReadMutationVariables }
) => (
  <ReactApollo.Mutation<
    MarkNotifsAsReadMutation,
    MarkNotifsAsReadMutationVariables
  >
    mutation={MarkNotifsAsReadDocument}
    {...props}
  />
)

export function useMarkNotifsAsReadMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    MarkNotifsAsReadMutation,
    MarkNotifsAsReadMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    MarkNotifsAsReadMutation,
    MarkNotifsAsReadMutationVariables
  >(MarkNotifsAsReadDocument, baseOptions)
}
export const FeaturedIdeasDocument = gql`
  query FeaturedIdeas {
    featuredIdeas {
      ...IdeaParts
      name
      id
      cover
      currentUserReaction
    }
  }
  ${IdeaPartsFragmentDoc}
`

export const FeaturedIdeasComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<FeaturedIdeasQuery, FeaturedIdeasQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: FeaturedIdeasQueryVariables }
) => (
  <ReactApollo.Query<FeaturedIdeasQuery, FeaturedIdeasQueryVariables>
    query={FeaturedIdeasDocument}
    {...props}
  />
)

export function useFeaturedIdeasQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FeaturedIdeasQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    FeaturedIdeasQuery,
    FeaturedIdeasQueryVariables
  >(FeaturedIdeasDocument, baseOptions)
}
export const NewChallengeDocument = gql`
  mutation NewChallenge($challenge: ChallengeInput!) {
    newChallenge(challenge: $challenge) {
      name
    }
  }
`
export type NewChallengeMutationFn = ReactApollo.MutationFn<
  NewChallengeMutation,
  NewChallengeMutationVariables
>

export const NewChallengeComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        NewChallengeMutation,
        NewChallengeMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: NewChallengeMutationVariables }
) => (
  <ReactApollo.Mutation<NewChallengeMutation, NewChallengeMutationVariables>
    mutation={NewChallengeDocument}
    {...props}
  />
)

export function useNewChallengeMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    NewChallengeMutation,
    NewChallengeMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    NewChallengeMutation,
    NewChallengeMutationVariables
  >(NewChallengeDocument, baseOptions)
}
export const UpdateChallengeDocument = gql`
  mutation UpdateChallenge($challengeId: ID!, $data: UpdateChallengeInput!) {
    updateChallenge(challengeId: $challengeId, data: $data) {
      message
      id
    }
  }
`
export type UpdateChallengeMutationFn = ReactApollo.MutationFn<
  UpdateChallengeMutation,
  UpdateChallengeMutationVariables
>

export const UpdateChallengeComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateChallengeMutation,
        UpdateChallengeMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateChallengeMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateChallengeMutation,
    UpdateChallengeMutationVariables
  >
    mutation={UpdateChallengeDocument}
    {...props}
  />
)

export function useUpdateChallengeMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateChallengeMutation,
    UpdateChallengeMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateChallengeMutation,
    UpdateChallengeMutationVariables
  >(UpdateChallengeDocument, baseOptions)
}
export const DeleteChallengeDocument = gql`
  mutation DeleteChallenge($challengeId: ID!) {
    deleteChallenge(challengeId: $challengeId) {
      message
      id
    }
  }
`
export type DeleteChallengeMutationFn = ReactApollo.MutationFn<
  DeleteChallengeMutation,
  DeleteChallengeMutationVariables
>

export const DeleteChallengeComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        DeleteChallengeMutation,
        DeleteChallengeMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: DeleteChallengeMutationVariables }
) => (
  <ReactApollo.Mutation<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >
    mutation={DeleteChallengeDocument}
    {...props}
  />
)

export function useDeleteChallengeMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >(DeleteChallengeDocument, baseOptions)
}
export const ChallengesDocument = gql`
  query Challenges {
    challenges {
      name
      id
      ideaQuestions {
        type
        label
      }
      additionalQuestions {
        text
        id
        selectList
        type
      }
      cover
      questions {
        id
        title
      }
      linkedIdeas {
        name
        id
      }
      enablePending
      collaboration
      judges {
        id
        name
        img
      }
      period {
        startDate
        dueDate
      }
      tags
      description
      shortDescription
    }
  }
`

export const ChallengesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<ChallengesQuery, ChallengesQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: ChallengesQueryVariables }
) => (
  <ReactApollo.Query<ChallengesQuery, ChallengesQueryVariables>
    query={ChallengesDocument}
    {...props}
  />
)

export function useChallengesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ChallengesQueryVariables>
) {
  return ReactApolloHooks.useQuery<ChallengesQuery, ChallengesQueryVariables>(
    ChallengesDocument,
    baseOptions
  )
}
export const IdeaChallengeRatingsDocument = gql`
  query IdeaChallengeRatings($ideaId: ID!, $challengeId: ID!) {
    ideaChallengeRatings(ideaId: $ideaId, challengeId: $challengeId) {
      id
      ratings {
        questionId
        rating
      }
      sender {
        id
        img
        name
        __typename @skip(if: true)
      }
      ratedAt
      average
      __typename @skip(if: true)
    }
  }
`

export const IdeaChallengeRatingsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        IdeaChallengeRatingsQuery,
        IdeaChallengeRatingsQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: IdeaChallengeRatingsQueryVariables }
) => (
  <ReactApollo.Query<
    IdeaChallengeRatingsQuery,
    IdeaChallengeRatingsQueryVariables
  >
    query={IdeaChallengeRatingsDocument}
    {...props}
  />
)

export function useIdeaChallengeRatingsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    IdeaChallengeRatingsQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    IdeaChallengeRatingsQuery,
    IdeaChallengeRatingsQueryVariables
  >(IdeaChallengeRatingsDocument, baseOptions)
}
export const StatsDocument = gql`
  query Stats {
    stats {
      users {
        totalUsers
        date
      }
      ideas {
        totalIdeas
        date
      }
      leaderboard {
        username
        points
        id
        image
      }
      types {
        type
        ideas
      }
    }
  }
`

export const StatsComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<StatsQuery, StatsQueryVariables>, 'query'>,
    'variables'
  > & { variables?: StatsQueryVariables }
) => (
  <ReactApollo.Query<StatsQuery, StatsQueryVariables>
    query={StatsDocument}
    {...props}
  />
)

export function useStatsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<StatsQueryVariables>
) {
  return ReactApolloHooks.useQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    baseOptions
  )
}
export const ChallengeEvolutionDocument = gql`
  query ChallengeEvolution($challengeId: ID!) {
    challenge(challengeId: $challengeId) {
      name
      shortDescription
      id
      cover
      tags
      creator {
        id
        name
      }
      challengeEvolution {
        id
        text
        color
      }
    }
  }
`

export const ChallengeEvolutionComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ChallengeEvolutionQuery,
        ChallengeEvolutionQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: ChallengeEvolutionQueryVariables }
) => (
  <ReactApollo.Query<ChallengeEvolutionQuery, ChallengeEvolutionQueryVariables>
    query={ChallengeEvolutionDocument}
    {...props}
  />
)

export function useChallengeEvolutionQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    ChallengeEvolutionQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    ChallengeEvolutionQuery,
    ChallengeEvolutionQueryVariables
  >(ChallengeEvolutionDocument, baseOptions)
}
export const ChallengeDocument = gql`
  query Challenge($challengeId: ID!) {
    challenge(challengeId: $challengeId) {
      name
      shortDescription
      description
      id
      cover
      creationDate
      tags
      status
      ideaQuestions {
        label
      }
      additionalQuestions {
        text
        type
        selectList
      }
      challengeEvolution {
        id
        color
        text
      }
      questions {
        title
        description
        scoreType
        maxRating
        rubrics {
          title
          description
          color
          range {
            min
            max
          }
        }
      }
      collaboration
      judges {
        id
        name
        img
      }
      enablePending
      period {
        startDate
        dueDate
      }
      attachments {
        name
        type
        url
      }
      creator {
        img
        name
        id
      }
      pendingIdeas {
        id
        name
        shortDescription
        description
        creator {
          id
          name
          img
        }
        cover
      }
    }
  }
`

export const ChallengeComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<ChallengeQuery, ChallengeQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables: ChallengeQueryVariables }
) => (
  <ReactApollo.Query<ChallengeQuery, ChallengeQueryVariables>
    query={ChallengeDocument}
    {...props}
  />
)

export function useChallengeQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ChallengeQueryVariables>
) {
  return ReactApolloHooks.useQuery<ChallengeQuery, ChallengeQueryVariables>(
    ChallengeDocument,
    baseOptions
  )
}
export const UpdateLinkedIdeaStageDocument = gql`
  mutation UpdateLinkedIdeaStage(
    $challengeId: ID!
    $ideaId: ID!
    $newStage: Int
  ) {
    updateLinkedIdeaStage(
      challengeId: $challengeId
      ideaId: $ideaId
      newStage: $newStage
    ) {
      message
      id
    }
  }
`
export type UpdateLinkedIdeaStageMutationFn = ReactApollo.MutationFn<
  UpdateLinkedIdeaStageMutation,
  UpdateLinkedIdeaStageMutationVariables
>

export const UpdateLinkedIdeaStageComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UpdateLinkedIdeaStageMutation,
        UpdateLinkedIdeaStageMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UpdateLinkedIdeaStageMutationVariables }
) => (
  <ReactApollo.Mutation<
    UpdateLinkedIdeaStageMutation,
    UpdateLinkedIdeaStageMutationVariables
  >
    mutation={UpdateLinkedIdeaStageDocument}
    {...props}
  />
)

export function useUpdateLinkedIdeaStageMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateLinkedIdeaStageMutation,
    UpdateLinkedIdeaStageMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateLinkedIdeaStageMutation,
    UpdateLinkedIdeaStageMutationVariables
  >(UpdateLinkedIdeaStageDocument, baseOptions)
}
export const LinkIdeaDocument = gql`
  mutation LinkIdea(
    $challengeId: ID!
    $ideaId: ID!
    $additionalQuestions: AdditionalQuestionAnswers
  ) {
    linkIdeaToChallenge(
      challengeId: $challengeId
      ideaId: $ideaId
      additionalQuestions: $additionalQuestions
    ) {
      name
      linkedIdeas {
        name
        id
      }
    }
  }
`
export type LinkIdeaMutationFn = ReactApollo.MutationFn<
  LinkIdeaMutation,
  LinkIdeaMutationVariables
>

export const LinkIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<LinkIdeaMutation, LinkIdeaMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: LinkIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<LinkIdeaMutation, LinkIdeaMutationVariables>
    mutation={LinkIdeaDocument}
    {...props}
  />
)

export function useLinkIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    LinkIdeaMutation,
    LinkIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    LinkIdeaMutation,
    LinkIdeaMutationVariables
  >(LinkIdeaDocument, baseOptions)
}
export const UnlinkIdeaDocument = gql`
  mutation UnlinkIdea($challengeId: ID!, $ideaId: ID!) {
    unlinkIdeaFromChallenge(challengeId: $challengeId, ideaId: $ideaId) {
      message
      id
    }
  }
`
export type UnlinkIdeaMutationFn = ReactApollo.MutationFn<
  UnlinkIdeaMutation,
  UnlinkIdeaMutationVariables
>

export const UnlinkIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        UnlinkIdeaMutation,
        UnlinkIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: UnlinkIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<UnlinkIdeaMutation, UnlinkIdeaMutationVariables>
    mutation={UnlinkIdeaDocument}
    {...props}
  />
)

export function useUnlinkIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UnlinkIdeaMutation,
    UnlinkIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UnlinkIdeaMutation,
    UnlinkIdeaMutationVariables
  >(UnlinkIdeaDocument, baseOptions)
}
export const ProposeIdeaDocument = gql`
  mutation ProposeIdea($challengeId: ID!, $ideaId: ID!) {
    proposeIdeaToChallenge(challengeId: $challengeId, ideaId: $ideaId) {
      name
      pendingIdeas {
        name
        id
      }
    }
  }
`
export type ProposeIdeaMutationFn = ReactApollo.MutationFn<
  ProposeIdeaMutation,
  ProposeIdeaMutationVariables
>

export const ProposeIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        ProposeIdeaMutation,
        ProposeIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: ProposeIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<ProposeIdeaMutation, ProposeIdeaMutationVariables>
    mutation={ProposeIdeaDocument}
    {...props}
  />
)

export function useProposeIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ProposeIdeaMutation,
    ProposeIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ProposeIdeaMutation,
    ProposeIdeaMutationVariables
  >(ProposeIdeaDocument, baseOptions)
}
export const RejectIdeaDocument = gql`
  mutation RejectIdea($challengeId: ID!, $ideaId: ID!) {
    rejectIdeaFromChallenge(challengeId: $challengeId, ideaId: $ideaId) {
      message
      id
    }
  }
`
export type RejectIdeaMutationFn = ReactApollo.MutationFn<
  RejectIdeaMutation,
  RejectIdeaMutationVariables
>

export const RejectIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        RejectIdeaMutation,
        RejectIdeaMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: RejectIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<RejectIdeaMutation, RejectIdeaMutationVariables>
    mutation={RejectIdeaDocument}
    {...props}
  />
)

export function useRejectIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    RejectIdeaMutation,
    RejectIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    RejectIdeaMutation,
    RejectIdeaMutationVariables
  >(RejectIdeaDocument, baseOptions)
}
export const RateIdeaDocument = gql`
  mutation RateIdea(
    $challengeId: ID
    $ideaId: ID!
    $ratings: [UserIdeaRatingInput]!
  ) {
    rateIdeaForChallenge(
      challengeId: $challengeId
      ideaId: $ideaId
      ratings: $ratings
    ) {
      challenge {
        name
        ratingType
        ratingAverage
        period {
          startDate
          dueDate
        }
        ratings {
          title
          id
          ratingAverage
        }
        currentUserRating {
          ratings {
            rating
            questionId
          }
        }
      }
    }
  }
`
export type RateIdeaMutationFn = ReactApollo.MutationFn<
  RateIdeaMutation,
  RateIdeaMutationVariables
>

export const RateIdeaComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<RateIdeaMutation, RateIdeaMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: RateIdeaMutationVariables }
) => (
  <ReactApollo.Mutation<RateIdeaMutation, RateIdeaMutationVariables>
    mutation={RateIdeaDocument}
    {...props}
  />
)

export function useRateIdeaMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    RateIdeaMutation,
    RateIdeaMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    RateIdeaMutation,
    RateIdeaMutationVariables
  >(RateIdeaDocument, baseOptions)
}
export const PointsDocument = gql`
  query Points {
    points {
      comment
      ideaCreation
      ideaEvolution
      reaction
      respondToChallenge
      ideaFavorite
      collaboration
      filtroRevision
      ideaGanadora
    }
  }
`

export const PointsComponent = (
  props: Omit<
    Omit<ReactApollo.QueryProps<PointsQuery, PointsQueryVariables>, 'query'>,
    'variables'
  > & { variables?: PointsQueryVariables }
) => (
  <ReactApollo.Query<PointsQuery, PointsQueryVariables>
    query={PointsDocument}
    {...props}
  />
)

export function usePointsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<PointsQueryVariables>
) {
  return ReactApolloHooks.useQuery<PointsQuery, PointsQueryVariables>(
    PointsDocument,
    baseOptions
  )
}
export const SetPointsDocument = gql`
  mutation SetPoints($points: PointsInput!) {
    points(points: $points) {
      comment
      ideaCreation
      ideaEvolution
      reaction
      respondToChallenge
      ideaFavorite
      collaboration
      filtroRevision
      ideaGanadora
    }
  }
`
export type SetPointsMutationFn = ReactApollo.MutationFn<
  SetPointsMutation,
  SetPointsMutationVariables
>

export const SetPointsComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<SetPointsMutation, SetPointsMutationVariables>,
      'mutation'
    >,
    'variables'
  > & { variables?: SetPointsMutationVariables }
) => (
  <ReactApollo.Mutation<SetPointsMutation, SetPointsMutationVariables>
    mutation={SetPointsDocument}
    {...props}
  />
)

export function useSetPointsMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    SetPointsMutation,
    SetPointsMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    SetPointsMutation,
    SetPointsMutationVariables
  >(SetPointsDocument, baseOptions)
}
export const AppSettingsDocument = gql`
  query AppSettings {
    appSettings {
      points {
        comment
        ideaCreation
        ideaEvolution
        reaction
        respondToChallenge
        ideaFavorite
        collaboration
        filtroRevision
        ideaGanadora
      }
      featuredIdeas
      ideaTypes {
        id
        name
      }
    }
  }
`

export const AppSettingsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<AppSettingsQuery, AppSettingsQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: AppSettingsQueryVariables }
) => (
  <ReactApollo.Query<AppSettingsQuery, AppSettingsQueryVariables>
    query={AppSettingsDocument}
    {...props}
  />
)

export function useAppSettingsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<AppSettingsQueryVariables>
) {
  return ReactApolloHooks.useQuery<AppSettingsQuery, AppSettingsQueryVariables>(
    AppSettingsDocument,
    baseOptions
  )
}
export const IdeaTypesDocument = gql`
  query IdeaTypes {
    appSettings {
      ideaTypes {
        id
        name
      }
    }
  }
`

export const IdeaTypesComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<IdeaTypesQuery, IdeaTypesQueryVariables>,
      'query'
    >,
    'variables'
  > & { variables?: IdeaTypesQueryVariables }
) => (
  <ReactApollo.Query<IdeaTypesQuery, IdeaTypesQueryVariables>
    query={IdeaTypesDocument}
    {...props}
  />
)

export function useIdeaTypesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IdeaTypesQueryVariables>
) {
  return ReactApolloHooks.useQuery<IdeaTypesQuery, IdeaTypesQueryVariables>(
    IdeaTypesDocument,
    baseOptions
  )
}
export const SetAdminSettingsDocument = gql`
  mutation SetAdminSettings($settings: SettingsInput!) {
    settings(settings: $settings) {
      points {
        comment
        ideaCreation
        ideaEvolution
        reaction
        respondToChallenge
        ideaFavorite
        collaboration
        filtroRevision
        ideaGanadora
      }
      featuredIdeas
      ideaTypes {
        id
        name
      }
    }
  }
`
export type SetAdminSettingsMutationFn = ReactApollo.MutationFn<
  SetAdminSettingsMutation,
  SetAdminSettingsMutationVariables
>

export const SetAdminSettingsComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        SetAdminSettingsMutation,
        SetAdminSettingsMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: SetAdminSettingsMutationVariables }
) => (
  <ReactApollo.Mutation<
    SetAdminSettingsMutation,
    SetAdminSettingsMutationVariables
  >
    mutation={SetAdminSettingsDocument}
    {...props}
  />
)

export function useSetAdminSettingsMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    SetAdminSettingsMutation,
    SetAdminSettingsMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    SetAdminSettingsMutation,
    SetAdminSettingsMutationVariables
  >(SetAdminSettingsDocument, baseOptions)
}
