// Material helpers
import { createMuiTheme } from '@material-ui/core/styles'

import palette from './palette'
import HEBpalette from './HEBpalette'
import SandboxPalette from './SandboxPalette'
import typography from './typography'
import overrides from './overrides'
import { getInstanceLayout } from '../Utils/getInstanceLayout'
import abancaPalette from './abancaPalette'
import CambiaHealthPalette from './cambiaHealthPalette'
import SocofarPalette from './socofarPalette'
import DowPalette from './dowPalette'
import LalaPalette from './lalaPalette'
import chubbPalette from './chubbPalette'
import caixaBankPalette from './caixaBankPalette'
import softtekAutomationPalette from './softtekAutomationPalette'
import benavidesPalette from './benavidesPalette'
let theme
theme = createMuiTheme({
  palette: getInstanceLayout({
    heb: HEBpalette,
    softtek: palette,
    sandbox: SandboxPalette,
    abanca: abancaPalette,
    cambiahealth: CambiaHealthPalette,
    socofar: SocofarPalette,
    dow: DowPalette,
    lala: LalaPalette,
    chubb: chubbPalette,
    caixabank: caixaBankPalette,
    softtekautomation: softtekAutomationPalette,
    benavides: benavidesPalette,
  }),
  typography: {
    ...typography,
    fontFamily: `'Open Sans', sans-serif`,
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  shape: { borderRadius: 10 },
})

export default theme
