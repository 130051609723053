import React from 'react'
import logo from '../../../../static/logo/logo.png'
import HEBlogo from '../../../../static/logo/Logotipo-Plataforma-INSPIRA.png'
import RerobotLogo from '../../../../static/logo/rerobot_innotek_logo.png'
import CaixaBank from '../../../../static/logo/caixabank_innitek_logo.png'
import { Link } from 'react-router-dom'
import { getInstanceLayout } from '../../../../Utils/getInstanceLayout'

const Logo: React.FC = () => {
  const scrollTop = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <>
      {window.location.pathname === '/' ? (
        <img
          src={getInstanceLayout({
            heb: HEBlogo,
            softtek: logo,
            sandbox: logo,
            abanca: RerobotLogo,
            caixabank: CaixaBank,
          })}
          onClick={scrollTop}
          alt="logo"
          style={{ marginLeft: '20px', maxHeight: '50px', cursor: 'pointer' }}
        />
      ) : (
        <Link to="/">
          <img
            src={getInstanceLayout({
              heb: HEBlogo,
              softtek: logo,
              sandbox: logo,
              abanca: RerobotLogo,
              caixabank: CaixaBank,
            })}
            alt="logo"
            style={{ marginLeft: '20px', maxHeight: '50px', cursor: 'pointer' }}
          />
        </Link>
      )}
    </>
  )
}

export default Logo
