import React from 'react'
import { useStyles } from './styles'
import { Link as BrowserLink } from 'react-router-dom'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Link,
  Divider,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import { Title } from '../../../../components/Shared'
import { useIdeaTypesQuery } from '../../../../generated/graphql'
import Loading from '../../../../components/Loading'
import { camelize } from '../../../../Utils/textUtils'
import TypeIcon from '../../../../components/TypeIcon'
import { _i } from '../../../../Utils/lang/i18n'
// Create trending idea types using graphql query
const TrendingIdeaTypes = () => {
  const { data, loading } = useIdeaTypesQuery()
  // @ts-ignore
  const classes = useStyles()
  if (loading || process.env.REACT_APP_SERVICE === 'abanca') {
    return <></>
  }
  const ideaTypes = data.appSettings ? data.appSettings.ideaTypes || [] : []
  return (
    <Grid container direction="column">
      <Title
        style={{
          margin: '8px 12px',
          fontFamily: 'InnoArial',
          fontSize: '22px',
        }}>
        {_i('Tipos de Ideas')}
      </Title>
      {loading && <Loading />}
      {!loading && (
        <>
          <List>
            {ideaTypes &&
              ideaTypes.map(type => (
                <div style={{ height: '52px' }} key={`${type.name}`}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <TypeIcon title={type.name.toLowerCase()} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText disableTypography>
                      <Link
                        variant="body1"
                        style={{ fontFamily: 'InnoArial' }}
                        component={BrowserLink}
                        to={`/type/${type.name}`}>
                        {camelize(type.name)}
                      </Link>
                    </ListItemText>
                    {/* <ListItemSecondaryAction>
                  <Typography
                    variant="body2"
                    style={{ fontFamily: 'InnoArial' }}>
                    {`${type.count} ${type.count === 1 ? 'idea' : 'ideas'}`}
                  </Typography>
                </ListItemSecondaryAction> */}
                  </ListItem>
                  <Divider light />
                </div>
              ))}
          </List>
          <div style={{ height: '56px' }} />
        </>
      )}
    </Grid>
  )
}

export default TrendingIdeaTypes
