import React, { useEffect, useState } from 'react'

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  connectStateResults,
  Configure,
  PoweredBy,
} from 'react-instantsearch-dom'
import './styles.css'
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import searchClient from '../../../algolia'
import useRouter from 'use-react-router'
import { getInstanceLayout } from '../../../Utils/getInstanceLayout'
import { _i } from '../../../Utils/lang/i18n'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'absolute' as 'absolute',
    maxHeight: 600,
    minHeight: 100,
    minWidth: 300,
    maxWidth: 400,
    letterSpacing: 0,
    borderRadius: '12px',
    marginTop: '10px',
  },
}))

interface Props {}

const Content = connectStateResults(
  ({ searchState, searchResults, children, open }) => {
    // @ts-ignore
    const classes = useStyles()
    if (!open) {
      return null
    }
    if (!searchState || !searchState.query) {
      return null
    }

    if (searchResults && searchResults.nbHits) {
      return <Paper className={classes.root}>{children}</Paper>
    } else {
      return (
        <Paper className={classes.root}>
          <Typography
            variant="body2"
            style={{ fontFamily: 'InnoArial', color: '#adadad' }}>
            {_i('No hubo resultados de esta búsqueda')}
          </Typography>
        </Paper>
      )
    }
  }
)

type ClassType = 'idea' | 'tag' | 'user'
// when results are hitted, then apply the hit component
const Hit = ({ hit }) => {
  const classType: ClassType = hit.class as ClassType
  const { history } = useRouter()

  const goTo = () => {
    // when hit is clicked, make the search bar content disapear,
    history.push(`/${hit.class}/${hit.id}`)
    // @ts-ignore
    Array.from(document.querySelectorAll('.ais-SearchBox-reset'))[0].click()
    // @ts-ignore
    // Array.from(
    //   document.querySelectorAll('.ais-SearchBox-reset')
    // )[0].activeElement = false
  }
  // useEffect(() => {
  //   console.log(hit)
  // }, [hit])

  return (
    <div className="hit" tabIndex={0} style={{ minWidth: 200 }} onClick={goTo}>
      <Typography
        variant="h6"
        style={{ fontFamily: 'InnoArial', color: '#adadad' }}>
        {classType}
      </Typography>
      <Typography variant="subtitle1">
        <Highlight attribute="name" hit={hit} />
      </Typography>
      {classType === 'idea' && (
        <Typography variant="body1">
          <Highlight attribute="shortDescription" hit={hit} />
        </Typography>
      )}
    </div>
  )
}
// Search bar component
const SearchTopBar: React.FC<Props> = () => {
  const [open, setOpen] = useState(true)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {}, [open])
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div onClick={handleOpen}>
        <InstantSearch
          searchClient={searchClient}
          indexName={getInstanceLayout({
            heb: 'InnotekHEBIndex',
            softtek: 'InnotekSofttekIndex',
            sandbox: 'InnotekSandboxIndex',
            osde: 'InnotekOSDEIndex',
            softtekbrazil: 'InnotekSofttekBrazilIndex',
            abanca: 'InnotekABANCAIndex',
            cambiahealth: 'InnotekCambiaHealthIndex',
            socofar: 'InnotekSocofarIndex',
            dow: 'InnotekDowIndex',
            vector: 'InnotekVectorIndex',
            metlife: 'InnotekMetlifeIndex',
            lala: 'InnotekLalaIndex',
            chubb: 'InnotekChubbIndex',
            caixabank: 'InnotekCaixaBankIndex',
            softtekautomation: 'InnotekSofttekAutomationIndex',
            benavides: 'InnotekBenavidesIndex',
          })}>
          <Configure hitsPerPage={4} />
          <SearchBox
            translations={{
              placeholder: _i('Buscar'),
            }}
          />

          <Content open={open}>
            <Hits hitComponent={Hit} />
            <Pagination />
          </Content>
        </InstantSearch>
      </div>
    </ClickAwayListener>
  )
}
const CustomHits = () => {}
export default SearchTopBar
